import LeftChevron from "../assets/images/LeftChevron.svg";
import ViewDocument from "../assets/images/ViewDocument.svg";
import EyeIcon from "../assets/images/eyeIcon.svg";
import DownloadIcon from "../assets/images/downloadIcon.svg";
import NotFoundImg from "../assets/images/404.png";
import riskshark from "../assets/images/Risksharkgroup.svg";
import arrow from "../assets/images/arrowsvg.svg";
import infoIcon from "../assets/images/icons/alert-circle.svg";
import greenIcon from "../assets/images/icons/arrow-green-up.svg";
import redIcon from "../assets/images/icons/arrow-red-down.svg";
import whiteArrow from "../assets/images/whitearrow.svg";
import Linkedin from "../assets/images/Linkedin.svg";
import Twitter from "../assets/images/Twitter.svg";
import StockWits from "../assets/images/StockWits.svg";
import CommentPlus from "../assets/images/comment-plus-outline.svg";
import DateIcon from "../assets/images/date-icon.svg";
import TickerIcon from "../assets/images/ticker-icon.svg";
import SectorIcon from "../assets/images/sector-icon.svg";
import IndustryIcon from "../assets/images/industry-icon.svg";
import FilingIcon from "../assets/images/filing-icon.svg";
import PositiveIcon from "../assets/images/positive-icon.svg";
import NegativeIcon from "../assets/images/negitive-icon.svg";
import NeutralIcon from "../assets/images/neutral-icon.svg";
import ChivronIcon from "../assets/images/ChivronIcon.svg";
import NoFLashReport from "../assets/images/NoFLashReport.svg";
import Frame from "../assets/images/Frame.svg";
import VerticalDots from "../assets/images/verticalDots.svg";
import EditIcon from "../assets/images/EditIcon.svg";
import DeleteIcon from "../assets/images/DeleteIcon.svg";
import FlashReportLink from "../assets/images/FlashReportLink.svg";
import closeIcon from "../assets/images/closeIcon.svg";
import greenDot from "../assets/images/green.svg";
import yellowDot from "../assets/images/yellow.svg";
import redDot from "../assets/images/red.svg";

export const images = {
  LeftChevron,
  ViewDocument,
  EyeIcon,
  DownloadIcon,
  NotFoundImg,
  riskshark,
  arrow,
  infoIcon,
  greenIcon,
  redIcon,
  whiteArrow,
  Linkedin,
  Twitter,
  StockWits,
  CommentPlus,
  DateIcon,
  TickerIcon,
  SectorIcon,
  IndustryIcon,
  FilingIcon,
  PositiveIcon,
  NegativeIcon,
  NeutralIcon,
  ChivronIcon,
  NoFLashReport,
  Frame,
  VerticalDots,
  EditIcon,
  DeleteIcon,
  FlashReportLink,
  closeIcon,
  greenDot,
  yellowDot,
  redDot,
};
