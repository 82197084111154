import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {},
};

const filtersTableSlice = createSlice({
  name: "setFilterValues",
  initialState,
  reducers: {
    setFilterValues: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export default filtersTableSlice.reducer;
export const { setFilterValues } = filtersTableSlice.actions;
