import React from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";
import { validationSchema } from "../FlashReportUtility";

const AddSentimentsForm = ({
  sentimentsData,
  handleChange,
  characterCount,
  handleClear,
  handleSubmitAddComment,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#1E2449" : "#1E2449",
      backgroundColor: state.isSelected ? "transparent" : "transparent",
      fontWeight: state.isSelected ? "bold" : "400",
      cursor: state.isDisabled ? "not-allowed !important" : "pointer",
    }),
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        sentiments: sentimentsData[0],
        title: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmitAddComment}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        resetForm,
        dirty,
      }) => (
        <Form
          onSubmit={(e) => handleSubmit(e, { resetForm, setFieldValue })}
          noValidate
          className="sentiments-form mb-16"
        >
          <div className="form-group mb-16">
            <div className="form-controls react-drop">
              <Select
                name="sentiments"
                options={sentimentsData}
                value={values?.sentiments}
                onChange={(e) => handleChange(setFieldValue, e)}
                getOptionLabel={(e) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={e.icon}
                      className="img-fluid"
                      alt="sentiments"
                      width={20}
                      height={20}
                    />

                    <span style={{ marginLeft: 5, fontSize: "1.4rem" }}>
                      {e.text}
                    </span>
                  </div>
                )}
                styles={customStyles}
                isSearchable={false}
              />
            </div>
          </div>
          <Form.Group className="form-group">
            <div className="form-controls">
              <Form.Control
                name="title"
                bsPrefix=" "
                placeholder="Add Your Title"
                type="text"
                value={values.title || ""}
                onChange={(e) => handleChange(setFieldValue, e)}
                className="sentiments-title"
              />
              {errors.title && touched.title && (
                <p className="error">{errors.title}</p>
              )}
            </div>
          </Form.Group>
          <p className="character-limit">
            Character Limit: {characterCount}/100
          </p>
          <div className="mt-20 d-flex flex-column flex-sm-row align-items-center justify-content-end">
            <button
              type="button"
              data-testid="clearbtn"
              className="btn clear-btn"
              onClick={() => handleClear(resetForm)}
            >
              Cancel
            </button>
            <button
              type="submit"
              data-testid="clearbtn"
              className={`btn btn-primary mb-3 mb-sm-0 btn-outline ${
                dirty ? "" : "disabled"
              }`}
              disabled={!dirty}
            >
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddSentimentsForm;
