import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFileId: "",
};

const selectedFileSlice = createSlice({
  name: "fileId",
  initialState,
  reducers: {
    fileId: (state, action) => {
      state.selectedFileId = action.payload;
    },
  },
});

export default selectedFileSlice.reducer;
export const { fileId } = selectedFileSlice.actions;
