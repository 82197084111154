import { postRequest } from "./interfaces/axios-methods";
import {
  GET_SENTIMENTS_API_URL,
  GET_QUARTERS_API_URL,
  GET_ALL_FILES_MEDIAN_PRESENTIMENTS,
} from "./interfaces/api-urls";

async function getSentiments(ticker, yearfilter) {
  try {
    const response = await postRequest(GET_SENTIMENTS_API_URL, {
      ticker: ticker,
      yearfilter: yearfilter,
    });
    return response;
  } catch (error) {
    return await error?.response?.data;
  }
}

async function getQuarters(ticker, filing_type) {
  try {
    const response = await postRequest(GET_QUARTERS_API_URL, {
      ticker: ticker,
      filing_type: filing_type,
    });
    return response;
  } catch (error) {
    return await error?.response?.data;
  }
}

async function getFileSentiments(id, quarter, fileyear, filing_type) {
  try {
    const response = await postRequest(GET_ALL_FILES_MEDIAN_PRESENTIMENTS, {
      id: id,
      quarter: quarter,
      fileyear: fileyear,
      filing_type: filing_type,
    });
    return response;
  } catch (error) {
    return await error?.response?.data;
  }
}

const SentimentService = {
  getSentiments,
  getQuarters,
  getFileSentiments,
};

export default SentimentService;
