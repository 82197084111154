import format from "date-fns/format";

// Convert Quarter-1, Quarter-3, Quarter-3, 10K into 10-Q and 10-K
const quarterConverter = (quarter) => {
  return quarter.includes("Quarter") ? "10-Q" : "10-K";
};

const makeLabelValuePair = (data) => {
  const option = data?.map((company) => ({
    label: company?.name,
    value: company?.ticker,
  }));
  return option;
};

const dateMMMddyyyy = (date) => {
  const newdate = format(date && new Date(date), "MMM dd, yyyy");
  return newdate;
};

const getYear = (fullDate) => {
  const date = new Date(fullDate);
  const year = date.getFullYear();
  return year;
};

const uniqueSectors = (data) => {
  const uniqueData = Array.from(new Set(data?.map((item) => item?.sector))).map(
    (sector) => data?.find((item) => item?.sector === sector)
  );
  // Sort the unique data based on the sector
  const sortedUniqueData = uniqueData?.sort((a, b) => {
    // Adjust the comparison logic based on your sector data type
    return a.sector.localeCompare(b.sector);
  });

  return sortedUniqueData;
};

const uniqueIndustries = (data) => {
  const uniqueData = Array.from(
    new Set(data?.map((item) => item?.industry))
  ).map((industry) => data?.find((item) => item?.industry === industry));

  // Sort the unique data based on the sector
  const sortedUniqueData = uniqueData?.sort((a, b) => {
    // Adjust the comparison logic based on your sector data type
    return a.industry.localeCompare(b.industry);
  });

  return sortedUniqueData;
};

const uniqueTable = (data) => {
  const uniqueData = Array.from(
    new Set(data?.map((item) => item?.ticker))
  )?.map((ticker) => data?.find((item) => item?.ticker === ticker));

  return uniqueData;
};

const showFileOrMedian = (data, type) => {
  const {
    wordType,
    median_constraining,
    file_constraining,
    median_litigious,
    file_litigious,
    median_negative,
    file_negative,
    median_positive,
    file_positive,
    median_strong_modal,
    file_strong_modal,
    median_uncertainty,
    file_uncertainty,
    median_weak_modal,
    file_weak_modal,
    avg_constraining,
    avg_litigious,
    avg_negative,
    avg_positive,
    avg_strong_modal,
    avg_uncertainty,
    avg_weak_modal,
  } = data;
  if (type === "file") {
    return wordType === "Constraining"
      ? file_constraining === null
        ? "--"
        : file_constraining
      : wordType === "Litigious"
      ? file_litigious === null
        ? "--"
        : file_litigious
      : wordType === "Negative"
      ? file_negative === null
        ? "--"
        : file_negative
      : wordType === "Positive"
      ? file_positive === null
        ? "--"
        : file_positive
      : wordType === "Strong Modal"
      ? file_strong_modal === null
        ? "--"
        : file_strong_modal
      : wordType === "Uncertainty"
      ? file_uncertainty === null
        ? "--"
        : file_uncertainty
      : wordType === "Weak Modal"
      ? file_weak_modal || file_strong_modal
        ? file_weak_modal || file_strong_modal
        : "--"
      : "";
  } else if (type === "median") {
    return wordType === "Constraining"
      ? median_constraining === null
        ? "--"
        : median_constraining
      : wordType === "Litigious"
      ? median_litigious === null
        ? "--"
        : median_litigious
      : wordType === "Negative"
      ? median_negative === null
        ? "--"
        : median_negative
      : wordType === "Positive"
      ? median_positive === null
        ? "--"
        : median_positive
      : wordType === "Strong Modal"
      ? median_strong_modal === null
        ? "--"
        : median_strong_modal
      : wordType === "Uncertainty"
      ? median_uncertainty === null
        ? "--"
        : median_uncertainty
      : wordType === "Weak Modal"
      ? median_weak_modal === null
        ? "--"
        : median_weak_modal
      : "";
  } else {
    return wordType === "Constraining"
      ? avg_constraining === null
        ? "--"
        : avg_constraining
      : wordType === "Litigious"
      ? avg_litigious === null
        ? "--"
        : avg_litigious
      : wordType === "Negative"
      ? avg_negative === null
        ? "--"
        : avg_negative
      : wordType === "Positive"
      ? avg_positive === null
        ? "--"
        : avg_positive
      : wordType === "Strong Modal"
      ? avg_strong_modal === null
        ? "--"
        : avg_strong_modal
      : wordType === "Uncertainty"
      ? avg_uncertainty === null
        ? "--"
        : avg_uncertainty
      : wordType === "Weak Modal"
      ? avg_weak_modal === null
        ? "--"
        : avg_weak_modal
      : "";
  }
};

export {
  makeLabelValuePair,
  dateMMMddyyyy,
  uniqueSectors,
  uniqueIndustries,
  uniqueTable,
  showFileOrMedian,
  getYear,
  quarterConverter,
};
