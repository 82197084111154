import { postRequest } from "./interfaces/axios-methods";
import {
  GET_FILES_API_URL,
  COMPARE_FILES_API_URL,
  FILES_SCORE_API_URL,
} from "./interfaces/api-urls";

async function getQuarters(ticker, filing_type) {
  try {
    const response = await postRequest(GET_FILES_API_URL, {
      ticker: ticker,
      filing_type: filing_type,
    });
    return await response?.data?.data;
  } catch (error) {
    return await error?.response?.data;
  }
}

async function compareFiles(ticker, filing_type, fileFirst_id, fileSecond_id) {
  try {
    const response = await postRequest(COMPARE_FILES_API_URL, {
      ticker: ticker,
      filing_type: filing_type,
      fileFirst_id: fileFirst_id,
      fileSecond_id: fileSecond_id,
    });
    return await response?.data;
  } catch (error) {
    return await error?.response?.data;
  }
}

async function filesScore(ticker, filing_type, fileFirst_id, fileSecond_id) {
  try {
    const response = await postRequest(FILES_SCORE_API_URL, {
      ticker: ticker,
      filing_type: filing_type,
      fileFirst_id: fileFirst_id,
      fileSecond_id: fileSecond_id,
    });
    return await response?.data?.data;
  } catch (error) {
    return await error?.response?.data;
  }
}

const DiffCheckerService = {
  getQuarters,
  compareFiles,
  filesScore,
};

export default DiffCheckerService;
