import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Form, Formik } from "formik";
import ReactSelect from "../../ui/form-fields/ReactSelect";
import CompaniesService from "../../../services/companies.service";
import { images } from "../../../helpers/images";
import { companyList } from "../../../actions/allCompaniesData";
import "./Header.scss";

const Header = () => {
  const { data } = useSelector((state) => state?.apidata);
  const [companiesList, setCompaniesList] = useState([]);
  const formikRef = useRef(); // Create a ref for the Formik component
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // setCompaniesList through redux data
  useEffect(() => {
    if (data) {
      setCompaniesList(
        data?.map((company) => ({
          label: company?.name,
          value: company?.ticker,
        }))
      );
    }
  }, []);

  // useEffect for getting companies
  useEffect(() => {
    if (location.pathname === "/") {
      getCompanies();
    }
  }, [location]);

  // call api and get companies and set res in setCompaniesList as a label value pairs for react select.
  const getCompanies = async () => {
    const res = await CompaniesService.getCompaniesList();
    setCompaniesList(
      res?.map((company) => ({
        label: company?.name,
        value: company?.ticker,
      }))
    );
    // save companies list in redux thats why use dispatch
    dispatch(companyList(res));
    // reset react select
    formikRef?.current.resetForm();
  };

  const initialValuesCompany = {
    company: "",
  };

  const handleChanges = (name, value, setFieldValue, label) => {
    setFieldValue(name, value, label);
    history.push(`/company-details/flash-report/${label}/${value}`);
  };

  const formatOptionLabelHandler = (option) => (
    <div className="w-100 d-flex justify-content-between">
      <span className="option-label">
        {option.label}
        <span className="option-value">{option.value}</span>
      </span>
      <img src={images.arrow} alt="arrow" className="img-fluid arrow-img" />
    </div>
  );

  return (
    <Navbar collapseOnSelect expand="lg" className="header_new">
      <div className="container-fluid">
        <Navbar.Brand
          as={Link}
          to="/"
          className="d-flex align-items-center mr-32"
        >
          <img
            src={images.riskshark}
            alt="Logo"
            height="30"
            className="img-fluid"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Formik
              enableReinitialize
              initialValues={initialValuesCompany}
              innerRef={formikRef}
            >
              {({ errors, values, setFieldValue }) => (
                <Form noValidate>
                  <div className="form-group">
                    <div className="form-controls react-drop">
                      <ReactSelect
                        name="company"
                        options={companiesList}
                        handleChanges={handleChanges}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        values={values}
                        placeholderText="Search by company name or ticker"
                        isSearchable
                        getOptionLabel={(option) => option.label} // Display label only
                        getOptionValue={(option) => option.value} // Value property for search
                        formatOptionLabel={(option) =>
                          formatOptionLabelHandler(option)
                        }
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Nav>
          <Nav className="link-items">
            <Nav.Link
              as={Link}
              to="/"
              className={`d-flex clr-white ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/recent-filing"
              className={`d-flex clr-white ${
                location.pathname === "/recent-filing" ? "active" : ""
              }`}
            >
              Recent Filings
            </Nav.Link>
            <a
              href="mailto:evan@riskshark.io"
              className={`d-flex clr-white`}
              style={{ textDecoration: "none" }}
            >
              Contact
            </a>
            <div className="media-icons">
              <a
                href="https://www.linkedin.com/company/96959468/admin/feed/posts/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={images.Linkedin}
                  alt="Linkedin"
                  className="mr-12 ml-8 img-fluid"
                />
              </a>
              <a
                href="https://stocktwits.com/d_risk"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={images.StockWits}
                  alt="StockWits"
                  className="mr-12 img-fluid"
                />
              </a>
              <a
                href="https://twitter.com/d__risk"
                target="_blank"
                rel="noreferrer"
              >
                <img src={images.Twitter} alt="Twitter" className="img-fluid" />
              </a>
            </div>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
