import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import truncate from "../../../helpers/truncate";

const TextOverlayToolTip = ({
  textString,
  charactersLength,
  icon,
  title,
  src,
  onCopy,
  text,
  flashReport,
}) => {
  return textString?.length > charactersLength || textString === "-" || icon ? (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-${textString}`}>
          {textString === "-" ? (
            <p
              style={{
                fontSize: "1.2rem",
                fontStyle: "bold",
              }}
            >
              {"We were unable to process the similarity score at this time"}
            </p>
          ) : icon ? (
            <p
              style={{
                fontSize: "1.2rem",
                fontStyle: "bold",
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </p>
          ) : (
            <p
              style={{
                fontSize: "1.2rem",
                fontStyle: "bold",
              }}
            >
              {textString}
            </p>
          )}
        </Tooltip>
      }
    >
      {icon ? (
        flashReport ? (
          <CopyToClipboard onCopy={onCopy} text={text}>
            <img src={src} className="img-fluid" alt="ViewDocument" />
          </CopyToClipboard>
        ) : (
          <img src={src} className="img-fluid" alt="ViewDocument" />
        )
      ) : (
        <p style={{ whiteSpace: "nowrap" }}>
          {truncate(textString, charactersLength)}
        </p>
      )}
    </OverlayTrigger>
  ) : (
    <p style={{ whiteSpace: "nowrap" }}>{textString}</p>
  );
};
export default TextOverlayToolTip;
