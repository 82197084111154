import { useState } from "react";
import moment from "moment";
import SentimentService from "../services/sentiment.service";
import FilingsService from "../services/filings.service";

const useSentimentsData = () => {
  const [chartData, setChartData] = useState([]);
  const [optionsQuarter, setOptionsQuarter] = useState([]);
  const [defaultQuarter, setDefaultQuarter] = useState();
  const [industryName, setIndustryName] = useState("");
  const [sentiments, setSentiments] = useState([]);
  const [prevDoc, setPrevDoc] = useState(false);
  const [indAvg, setIndAvg] = useState(true);
  const [prevSentiments, setPrevSentiments] = useState([]);

  let globalStockPrice = [];

  // Convert Quarter-1, Quarter-2, Quarter-3, 10K into 10-Q and 10-K
  const quarterConverter = (quarter) => {
    return quarter.includes("10K") ? "10K" : "10Q";
  };

  const getSentimentsOnFilters = async (companyTicker, year, companyName) => {
    const getSentimentResponse = await SentimentService.getSentiments(
      companyTicker,
      year
    );
    let chartsData = await getSentimentResponse?.data?.data;
    const getStockPriceResponse = await FilingsService.getStockPrice(
      companyTicker,
      year === 2 ? "2y" : year === 5 ? "5y" : year === 10 ? "10y" : "5y"
    );
    let timestamps = await getStockPriceResponse?.data?.data?.chart?.result?.[0]
      ?.timestamp;
    let stockPrices = await getStockPriceResponse?.data?.data?.chart
      ?.result?.[0]?.indicators?.quote?.[0]?.close;
    let filingQuarterLength = chartsData?.length;
    var fileCounter = 0;
    let timestampsToDate = [];
    for (let i = 0; i < timestamps?.length; i++) {
      let date = new Date(timestamps[i] * 1000);

      let years = date.getFullYear();
      let months = date.getMonth() + 1;
      let days = date.getDate();

      timestampsToDate.push(years + "-" + months + "-" + days);

      if (
        fileCounter < filingQuarterLength &&
        moment(chartsData[fileCounter]?.date).format("YYYY-M-D") ===
          timestampsToDate[i]
      ) {
        globalStockPrice.push(
          (stockPrices[i] === null || stockPrices[i]) === 0
            ? (Math.round(stockPrices[i - 1] * 100) / 100).toFixed(2)
            : (Math.round(stockPrices[i] * 100) / 100).toFixed(2)
        );
        fileCounter = fileCounter + 1;
      }
    }
    var chartArray = [
      [
        "Year",
        "Industry Median",
        { type: "string", role: "style" },
        { role: "tooltip", p: { html: true } },
        "Curr File",
        { role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
      ],
    ];
    let oldDate = null;
    let count1 = 0;
    let previousDate = chartsData[0]?.date.substr(0, 4);
    chartsData?.map((item, index) => {
      if (previousDate === item?.date.substr(0, 4)) {
        if (count1 === 0) {
          oldDate = previousDate;
        } else {
          oldDate = "";
        }
      } else {
        previousDate = item?.date.substr(0, 4);
        oldDate = previousDate;
      }
      count1++;

      chartArray.push([
        oldDate,
        item?.avg_riskscore ? parseFloat(item?.avg_riskscore) : 0,
        "point {fill-color: #6F738A }",
        `<p><span className="span-head">Risk Score</span></br><span className="dim-span">Industry Avg Risk Score: </span><b>${parseFloat(
          item?.avg_riskscore
        ).toFixed(3)}</b><br/><span className="dim-span">${
          companyName + " " + item?.quarter
        } Risk Score: </span><b>${parseFloat(item?.riskscore).toFixed(
          3
        )}</b></br><span className="dim-span">Quarter: </span><b>${
          item?.quarter
        }</b><br><span className="dim-span">${moment(item?.date).format(
          " MMM, D, YYYY"
        )}</span><br><span className="dim-span">Price: </span><b>${`$${globalStockPrice[index]}`}</b></p>`,
        item?.riskscore ? parseFloat(item?.riskscore) : 0,
        `<p><span className="span-head">Risk Score</span></br><span className="dim-span">Industry Avg Risk Score: </span><b>${parseFloat(
          item?.avg_riskscore
        ).toFixed(3)}</b><br/><span className="dim-span">${
          companyName + " " + item?.quarter
        } Risk Score: </span><b>${parseFloat(item?.riskscore).toFixed(
          3
        )}</b></br><span className="dim-span">Quarter: </span><b>${
          item?.quarter
        }</b><br><span className="dim-span">${moment(item?.date).format(
          " MMM, D, YYYY"
        )}</span><br><span className="dim-span">Price: </span><b>${`$${globalStockPrice[index]}`}</b></p>`,
        "point {fill-color: #6F738A; }",
      ]);
    });
    setChartData(chartArray);
  };

  const getQuarters = async (
    companyTicker,
    filing_type,
    setFileUrl,
    selectedFiling
  ) => {
    const getQuartersResponse = await SentimentService.getQuarters(
      companyTicker,
      filing_type
    );
    let optionQuarter = await getQuartersResponse?.data?.data;
    let quarter = quarterConverter(optionQuarter[0]?.quarter);
    let quarterDate = moment(optionQuarter[0]?.date).format("MMM D, YYYY");
    setOptionsQuarter(
      optionQuarter?.map((item) => ({
        value:
          (item?.quarter?.includes("Quarter") ? "10Q" : "10K") +
          ": " +
          moment(item?.date).format("MMM D, YYYY"),
        label:
          (item?.quarter?.includes("Quarter") ? "10Q" : "10K") +
          ": " +
          moment(item?.date).format("MMM D, YYYY"),
        subLabel: moment(item?.date).format("MMM D, YYYY"),
        id: item?.id,
        quarter: item?.quarter,
        fileYear: item?.fileyear,
      }))
    );
    setDefaultQuarter({
      value: quarter + ": " + quarterDate,
      label: quarter + ": " + quarterDate,
    });
    getFileSentiments(optionQuarter[0], setFileUrl, selectedFiling);
  };

  const getFileSentiments = async (file, setFileUrl, selectedFiling) => {
    const getFileSentimentsResponse = await SentimentService.getFileSentiments(
      file?.id,
      file?.quarter,
      file?.fileYear,
      selectedFiling?.value
    );
    setIndustryName(getFileSentimentsResponse?.data?.data?.industry);
    setSentiments(getFileSentimentsResponse?.data?.data?.avg_sentiments);
    setFileUrl(getFileSentimentsResponse?.data?.data?.fileurl);
    if (getFileSentimentsResponse?.data?.data?.prev === true) {
      setPrevDoc(true);
      setIndAvg(false);
      setPrevSentiments(getFileSentimentsResponse?.data?.data?.pre_sentiments);
    } else {
      setPrevDoc(false);
      setIndAvg(true);
      setPrevSentiments([]);
    }
  };

  return {
    getSentimentsOnFilters,
    getQuarters,
    getFileSentiments,
    chartData,
    optionsQuarter,
    defaultQuarter,
    setDefaultQuarter,
    industryName,
    sentiments,
    prevDoc,
    indAvg,
    prevSentiments,
    quarterConverter,
  };
};

export default useSentimentsData;
