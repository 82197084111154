import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import configureStores from './store';
import { SetupInterceptors } from './services/interfaces/axios-client';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../src/assets/scss/screen.css";
import "@popperjs/core";
import "./index.css";
// import store from "./store";
// import { transitions, positions, Provider as AlertProvider } from "react-alert";
// import AlertTemplate from "react-alert-template-basic";
// const options = {
//   // you can also just use 'bottom center'
//   position: positions.TOP_RIGHT,
//   timeout: 10000,
//   offset: "30px",
//   // you can also just use 'scale'
//   transition: transitions.SCALE,
// };

const store = configureStores().store;
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={configureStores().persistor}>
      {/* <AlertProvider template={AlertTemplate} {...options}> */}
      <App />
    </PersistGate>
    {/* </AlertProvider> */}
  </Provider>,
  document.getElementById("onboarding")
);

//Pass store to this function because store is not accessible without functional component
SetupInterceptors(store);

serviceWorker.unregister();


