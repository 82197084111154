import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import RiskAnalysisResults from "./RiskAnalysisResults";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import { images } from "../../../helpers/images";
import {
  headersDataBody,
  optionsYear,
  customStyles,
} from "../../../helpers/constants";
import {
  firstChartInitialState,
  secondChartInitialState,
} from "./RiskAnalysisCharts";
import useGoogleAnalytics from "../../../hooks/useGoogleAnalytics";
import useStockData from "../../../hooks/useStockData";
import "./RiskAnalysis.scss";


const RiskAnalysis = ({ companyTicker, setFileUrl }) => {
  const fileId = useSelector((state) => state.selectedFileSlice.selectedFileId);
  const [selectedYear, setSelectedYear] = useState();
  const [headersData, setHeadersData] = useState(headersDataBody);
  const [refresh, setRefresh] = useState(false);
  const { trackEvent } = useGoogleAnalytics();
  const { resultData, showFiling, chartData, showFil,getStockPrice,handleClick } = useStockData();


 
  // first chart design
  const [firstChartOptions, setFirstChartOptions] = useState(
    firstChartInitialState
  );
  // second chart design
  const [chartOptions, setChartOptions] = useState(secondChartInitialState);

  useEffect(() => {
    setHeadersData(headersDataBody);
    setRefresh(true);
    if (optionsYear?.length > 0 && companyTicker && fileId) {
      getStockPrice(companyTicker, 10, fileId);
      setSelectedYear({
        value: optionsYear[2]?.value,
        label: optionsYear[2]?.label,
      });
    }
  }, [fileId]);

  useEffect(() => {
    // Track a pageview with a custom page name
    trackEvent(null, null, "pageview", "Risk Analysis");
  }, []);


  // 1st chart
  useEffect(() => {
    setFirstChartOptions((prev) => {
      return {
        ...prev,
        xAxis: {
          categories: chartData,
          labels: {
            rotation: -65,
          },
          gridLineColor: "#E4EBF6",
          visible: true,
        },
        series: [
          {
            data: showFil,
          },
        ],
      };
    });
  }, [chartData, showFil]);

  //2nd
  useEffect(() => {
    setChartOptions((prev) => {
      return {
        ...prev,
        xAxis: {
          categories: chartData,
          labels: {
            rotation: -65,
          },
          gridLineColor: "#E4EBF6",
          visible: true,
        },
        series: [
          {
            data: showFiling,
          },
        ],
      };
    });
  }, [chartData, showFiling]);

  useEffect(() => {
    if (resultData) {
      setFileUrl(resultData?.point?.options?.file_url || resultData?.file_url);
    }
  }, [resultData]);



  const externalFileFunc = (value) => {
    handleClick(value);
  };

  window.externalFileFunc = externalFileFunc;

  const handleSetOptionsYear = (e) => {
    setSelectedYear({ value: e?.value, label: e?.label });
    getStockPrice(companyTicker, e?.value);
  };

  return (
    <div className="row">
      <div className="col-md-7 col-lg-8">
        <div className="risk-analysis-content">
          <div className="d-flex justify-content-between">
            <div>
              <h2>Analysis</h2>
            </div>
            <div className="risk-analysis-filled-main">
              <ul className="inline-ul legend-diff-ul">
                <li>
                  <div className="d-flex align-items-center">
                    <div className="box-legend box-orange"></div>
                    <div className="p4">
                      <p>10Q Filed</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <div className="box-legend box-yellow"></div>
                    <div className="p4">
                      <p>10K Filed</p>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="form-group risk-analysis-select">
                <div className="form-controls react-drop react-drop-small">
                  <Select
                    value={selectedYear}
                    onChange={handleSetOptionsYear}
                    options={optionsYear}
                    styles={customStyles}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24 mb-24">
            <HighchartsReact
              highcharts={Highcharts}
              options={firstChartOptions}
            />
          </div>
          <div className="mt-16">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
          <div className="info-link mt-5 mb-2">
            <img src={images.infoIcon} className="me-2" alt="infoIcon" />
            <a
              href="https://github.com/ProsusAI/finBERT"
              target="_blank"
              rel="noreferrer"
            >
              Risk score calculation method
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-5 ">
        <RiskAnalysisResults
          resultData={resultData}
          refresh={refresh}
          headersData={headersData}
        />
      </div>
    </div>
  );
};

export default RiskAnalysis;