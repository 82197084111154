// Risk Analysis First Chart Initial State
export const firstChartInitialState = {
  chart: {
    type: "spline",
    color: "#B9C3D7",
    height: "250px",
  },

  plotOptions: {
    spline: {
      turboThreshold: 1000000,
    },
    dataLabels: {
      enabled: false,
    },
    series: {
      color: "#B9C3D7",

      backgroundColor:
        "linear-gradient(148.02deg, #0A4CA9 -7.75%, rgba(255, 255, 255, 0) 71.36%)",
      connectNulls: true,
    },
  },

  title: {
    text: "Stock Price",
  },
  yAxis: {
    title: {
      text: "Stock",
    },

    tickInterval: 50,
    gridLineColor: "#E4EBF6",
    visible: true,
    gridLineWidth: 0,
    lineWidth: 1,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    borderWidth: 0,
    shadow: true,
    useHtml: true,
    backgroundColor: "#fff",
    boxShadow: "0px 4px 23px rgba(30, 36, 73, 0.12)",
    borderRadius: ".8rem",
    formatter: function () {
      return (
        this.series?.xAxis?.chart?.hoverPoint?.colorIndex +
        " " +
        this.series?.xAxis?.chart?.hoverPoint?.quarter +
        "<br>" +
        "" +
        " " +
        this.series?.xAxis?.chart?.hoverPoint?.dates +
        "<br>" +
        "" +
        "Risk Score: " +
        this.series?.xAxis?.chart?.hoverPoint?.risk_factors +
        "</br> " +
        "Stock Price: " +
        this.series?.xAxis?.chart?.hoverPoint?.stockprice
      );
    },
  },
  series: [
    {
      point: {
        events: {
          mouseOver() {
            this.color = "black";
          },
        },
      },
      year: "%Y",
      colorAxis: "#094AA6",
    },
  ],
};

export const handleClickChart = (value) => {
  if (typeof window.externalFileFunc === "function") {
    window.externalFileFunc(value);
  }
};

export const secondChartInitialState = {
  chart: {
    type: "spline",
    color: "#B9C3D7",
    height: "300px",
  },
  plotOptions: {
    series: {
      color: "#B9C3D7",
      backgroundColor:
        "linear-gradient(148.02deg, #0A4CA9 -7.75%, rgba(255, 255, 255, 0) 71.36%)",
      turboThreshold: 1000000,
    },
  },

  title: {
    text: "Risk Score",
  },
  yAxis: {
    title: {
      text: "",
    },
    gridLineColor: "#E4EBF6",
    visible: true,
    gridLineWidth: 0,
    allowDecimals: true,
    lineWidth: 1,
    tickPositions: [-2, 0, 2], // Specify the positions you want to label
    labels: {
      enabled: true,
      formatter: function () {
        // Define custom labels for each position
        switch (this.value) {
          case -2:
            return "Lower Risk";
          case 0:
            return "Neutral";
          case 2:
            return "Higher Risk";
          default:
            return ""; // Hide labels for other positions
        }
      },
    },

    plotLines: [
      {
        value: 2,
        width: 2,
        color: "#B9C3D7",
        dashStyle: "shortdash",
      },

      {
        value: 0,
        width: 2,
        color: "#B9C3D7",
        dashStyle: "shortdash",
      },
      {
        value: -2,
        width: 2,
        color: "#B9C3D7",
        dashStyle: "shortdash",
      },
    ],
  },
  xAxis: {
    offset: 25,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    borderWidth: 0,
    shadow: true,
    useHtml: true,
    backgroundColor: "#fff",
    boxShadow: "0px 4px 23px rgba(30, 36, 73, 0.12)",
    borderRadius: ".8rem",
    formatter: function () {
      return (
        this.series?.xAxis?.chart?.hoverPoint?.colorIndex +
        " " +
        this.series?.xAxis?.chart?.hoverPoint?.quarter +
        "</b>,<br>" +
        this.series?.xAxis?.chart?.hoverPoint?.dates +
        "</b>,<br>" +
        "Risk Score:" +
        " " +
        this.series?.xAxis?.chart?.hoverPoint?.risk_factors +
        "</b>,<br>" +
        "Stock Price:" +
        " " +
        this.series?.xAxis?.chart?.hoverPoint?.stockprice
      );
    },
  },
  series: [
    {
      events: {
        click: handleClickChart,
      },
      point: {
        events: {
          mouseOver() {
            this.color = "black";
          },
        },
      },
      year: "%Y",
      colorAxis: "#094AA6",
    },
  ],
};
