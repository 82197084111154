import React from "react";
import { useTable, useSortBy } from "react-table";
import BTable from "react-bootstrap/Table";

const Table = (props) => {
  const { columns, data, rowProps = () => ({}) } = props;

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    page,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <>
      {data && (
        <BTable {...getTableProps()} className="table rs-table table-cl">
          <thead>
            {headerGroups?.map((headerGroup, id) => (
              <tr key={id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers?.map((column, id) => (
                  <th
                    key={id}
                    // {...column.getHeaderProps(!isLandingTable && column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* {!isLandingTable  && <span
                      className={`icon-chevronleft ${
                        column.isSorted
                          ? column.isSortedDesc === true
                            ? "icon-chevronleft"
                            : column.isSortedDesc === false
                            ? "icon-chevronleft arrow-up"
                            : ""
                          : ""
                      }`}
                    ></span> } */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.length === 0 && (
              <tr>
                <td colSpan={columns.length} className="no-data-found">
                  <h6>No result found</h6>
                </td>
              </tr>
            )}
            {rows?.map((row, id) => {
              prepareRow(row);
              return (
                <tr
                  key={id}
                  {...row.getRowProps()}
                  className={row.isSelected ? "selectedRow" : ""}
                >
                  {row.cells?.map((cell, id) => {
                    return (
                      <td key={id} {...cell.getCellProps(rowProps(row))}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BTable>
      )}
    </>
  );
};

export default Table;
