import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CompanyColumns } from "../../helpers/Columns";
import Table from "../../components/ui/table/Table";
import { images } from "../../helpers/images";
import "./Landing.scss";
import useGoogleAnalytics from "./../../hooks/useGoogleAnalytics";

const Landing = () => {
  const { data } = useSelector((state) => state?.apidata);
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const { trackEvent } = useGoogleAnalytics();

  useEffect(() => {
    if (data) {
      const slicingData = data?.slice(0, 5);
      setTableData(slicingData);
    }
  }, [data]);

  useEffect(() => {
    // Track a pageview with a custom page name
    trackEvent(null, null, "pageview", "Landing Page");
  }, []);

  const cols = useMemo(() => CompanyColumns, []);

  const viewAllRecentFiling = () => {
    history.push("/recent-filing");
    trackEvent("View all File Button", "Click", "event");
  };

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row pt-32 hero-content justify-content-center">
            <div className="col-md-12">
              <p className="fs-44 large-content">
                Uncover Hidden Truths in Financial Data
              </p>
              <p className="fs-24 large-content middle-content">
                Welcome to dRISK - Your Textual Analysis Tool
              </p>
              <h3 className="small-content">
                Compare textual differences within 10K and 10Q documents
              </h3>
            </div>
            <div className="table-section pt-20">
              <div className="table-content tableresponsive table-wrapper table-wrapper-cl">
                <Table
                  columns={cols}
                  data={tableData}
                  isLandingTable={true}
                  rowProps={(row) => ({
                    onClick: () => {
                      history.push(
                        `/company-details/flash-report/${row?.original?.name}/${row?.original?.ticker}`
                      );
                      trackEvent(
                        `Company - '${row?.original?.name}' clicked`,
                        "Click",
                        "event"
                      );
                    },
                    style: {
                      cursor: "pointer",
                    },
                  })}
                />
                <div className="row disclaimer">
                  <div className="md-12">
                    <div className="d-lg-flex align-items-center disclaimer-parent">
                      <div className="flex-grow-1">
                        <p className="disclaimer-content">
                          Disclaimer: dRISK is a software tool designed to
                          assist with textual analysis of financial documents.
                          It does not provide financial advice or guarantee
                          specific outcomes. Users are responsible for their
                          investment decisions.
                        </p>
                      </div>

                      <button
                        className="btn btn-primary"
                        onClick={viewAllRecentFiling}
                      >
                        View All Recent Filings
                        <img
                          src={images.whiteArrow}
                          alt="arrow"
                          className="ml-8 img-fluid"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Landing;
