import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fileId } from "../../../actions/selectedFileSlice";
import { filesDetail } from "../../../actions/diffCheckerSlice";
import { setDetailValues } from "../../../actions/flashReportSlice";
import { dateMMMddyyyy } from "../../../helpers/utility-functions";
import DiffCheckerService from "../../../services/diffchecker.service";
import RSLoader from "../../../helpers/RSLoader";

const DiffChecker = ({ companyTicker, setFileUrl, innerRef, setDocTitle }) => {
  const allSelectedFiles = useSelector(
    (state) => state.diffCheckerSlice.selectedFilesDetail
  );

  const selectedFileId = useSelector(
    (state) => state.selectedFileSlice.selectedFileId
  );

  const [filesList, setFilesList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [compareFilesUrl, setCompareFilesUrl] = useState("");
  const [firstFileDetails, setFirstFileDetails] = useState();
  const [secondFileDetails, setSecondFileDetails] = useState();
  const [quarterValue, setQuarterValue] = useState();
  const [similarityScore, setSimilarityScore] = useState();
  const [filesCta, setFilesCta] = useState();
  const [loader, setLoader] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    // Scroll the page by 10 pixels
    window.scrollTo(0, 70);
  }, []);

  useEffect(() => {
    if (companyTicker) {
      getFilesList();
    }
  }, [companyTicker]);

  const getFilesList = async () => {
    const res = await DiffCheckerService.getQuarters(companyTicker, "");
    setFilesList(res);
  };

  // Convert Quarter-1, Quarter-3, Quarter-3, 10K into 10-Q and 10-K
  const quarterConverter = (quarter) => {
    return quarter.includes("Quarter") ? "10-Q" : "10-K";
  };
  // set prev and next file info into setDocTitle to show the title of pdf file document when file save
  const handlePdfFileTitle = (prev, next) => {
    setDocTitle({
      prev,
      next,
    });
  };

  const compareFiles = async () => {
    /* We can use find method as they return the selected file object as we can compare this file already and store in redux */
    const selectedFile = await allSelectedFiles?.find(
      (files) => files?.firstFileId === selectedFileId
    );

    /* 
    when we come back from risk analysis and find this selected file Id if it returns selectedFile then select the same files 
    */
    if (
      selectedFile?.ticker === companyTicker &&
      Math.floor((Date.now() - selectedFile?.time) / 60000) <= 59
    ) {
      setSelectedFiles([selectedFile?.prev, selectedFile?.next]);
      setFirstFileDetails(filesList[selectedFile?.prev]);
      setSecondFileDetails(filesList[selectedFile?.next]);
      setQuarterValue(selectedFile?.quarterValue);
      setFilesCta(selectedFile?.cta);
      dispatch(
        setDetailValues({
          firstFile: filesList[selectedFile?.prev],
          secondFile: filesList[selectedFile?.next],
          quarter: selectedFile?.quarterValue,
        })
      );
      // setFilesDetails({
      //   firstFile: filesList[selectedFile?.prev],
      //   secondFile: filesList[selectedFile?.next],
      //   quarter: selectedFile?.quarterValue,
      // });
      handlePdfFileTitle(
        filesList[selectedFile?.prev],
        filesList[selectedFile?.next]
      );
      setSimilarityScore(selectedFile?.score);
      setFileUrl(selectedFile?.resUrl);
      setLoader(true);
      return convertHtmlToReact(selectedFile?.resUrl);
    }
    // Set the default selected values when the component mounts
    let fileIndex = 0;
    let nextIndex;
    let secondFile;
    let quarter = filesList[0]?.quarter;
    let quarterValue = quarterConverter(quarter);
    setQuarterValue(quarterValue);

    if (quarterValue === "10-K") {
      // Find the index of the next 10k file
      nextIndex = filesList?.findIndex(
        (file, index) => index > fileIndex && file.quarter === "10K"
      );
      secondFile = filesList?.find(
        (file, index) => index > fileIndex && file.quarter === "10K"
      );
    } else {
      // Find the index of the next file with the same quarter
      nextIndex = filesList?.findIndex(
        (file, index) =>
          index > fileIndex && quarterConverter(file?.quarter) === quarterValue
      );
      secondFile = filesList?.find(
        (file, index) =>
          index > fileIndex && quarterConverter(file?.quarter) === quarterValue
      );
    }

    const selectedFiles = await allSelectedFiles?.find(
      (files) =>
        files.prev === fileIndex &&
        files.next === nextIndex &&
        files.ticker === companyTicker
    );

    /* 
    const  { prev, next, score, resUrl, ticker, time } = selectedFile
     this is for avoiding compare files API calling  if we have these value from redux
     then we don't call API and <= 59 check is for after this time file url is removed
     from server then we can call Api again 
     */
    if (
      selectedFiles?.ticker === companyTicker &&
      Math.floor((Date.now() - selectedFiles?.time) / 60000) <= 59
    ) {
      setSelectedFiles([selectedFiles?.prev, selectedFiles?.next]);
      dispatch(fileId(selectedFiles?.firstFileId));
      setFirstFileDetails(filesList[selectedFiles?.prev]);
      setSecondFileDetails(filesList[selectedFiles?.next]);
      setQuarterValue(selectedFiles?.quarterValue);
      setFilesCta(selectedFiles?.cta);
      dispatch(
        setDetailValues({
          firstFile: filesList[selectedFile?.prev],
          secondFile: filesList[selectedFile?.next],
          quarter: selectedFile?.quarterValue,
        })
      );
      // setFilesDetails({
      //   firstFile: filesList[selectedFile?.prev],
      //   secondFile: filesList[selectedFile?.next],
      //   quarter: selectedFile?.quarterValue,
      // });
      handlePdfFileTitle(
        filesList[selectedFile?.prev],
        filesList[selectedFile?.next]
      );
      setSimilarityScore(selectedFiles?.score);
      setFileUrl(selectedFiles?.resUrl);
      setLoader(true);
      return convertHtmlToReact(selectedFiles?.resUrl);
    }

    setSelectedFiles([fileIndex, nextIndex]);
    const firstFile_id = filesList[0]?.id;
    const secondFile_id = secondFile?.id;
    dispatch(fileId(filesList[0]?.id));
    setFirstFileDetails(filesList[0]);
    setSecondFileDetails(secondFile);
    dispatch(
      setDetailValues({
        firstFile: filesList[0],
        secondFile: secondFile,
        quarter: quarterValue,
      })
    );
    // setFilesDetails({
    //   firstFile: filesList[0],
    //   secondFile: secondFile,
    //   quarter: quarterValue,
    // });
    handlePdfFileTitle(filesList[0], secondFile);
    // const filesScoreRes = await DiffCheckerService.filesScore(
    //   companyTicker,
    //   quarterValue,
    //   firstFile_id,
    //   secondFile_id
    // );
    // setSimilarityScore(filesScoreRes?.first_similarity); May be use later
    setLoader(true);
    const res = await DiffCheckerService.compareFiles(
      companyTicker,
      quarterValue,
      firstFile_id,
      secondFile_id
    );
    let compareFileUrl = res?.data;
    let compareFilesCta = res?.files;
    dispatch(
      filesDetail({
        prev: fileIndex,
        next: nextIndex,
        // score: filesScoreRes?.first_similarity,
        resUrl: compareFileUrl,
        ticker: companyTicker,
        time: Date.now(),
        firstFileId: firstFile_id,
        quarterValue: quarterValue,
        cta: compareFilesCta,
      })
    );
    setFileUrl(res);
    setFilesCta(res?.files);

    await convertHtmlToReact(res?.data);
  };

  const convertHtmlToReact = async (url) => {
    try {
      const response = await axios.get(url);
      if (response?.data) {
        setCompareFilesUrl(response?.data);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      return await error;
    }
  };

  useEffect(() => {
    if (filesList?.length > 0) {
      compareFiles();
    }
  }, [filesList]);

  const handleFileClick = async (fileIndex, quarter) => {
    const selectedIndex = selectedFiles.indexOf(fileIndex);
    let nextIndex;
    let firstFile;
    let secondFile;
    let quarterValue = quarterConverter(quarter);
    setQuarterValue(quarterValue);

    if (quarterValue === "10-K") {
      // Find the index of the next 10k file
      nextIndex = filesList?.findIndex(
        (file, index) => index > fileIndex && file.quarter === "10K"
      );
      firstFile = filesList?.find(
        (file, index) => index === fileIndex && file.quarter === "10K"
      );
      secondFile = filesList?.find(
        (file, index) => index > fileIndex && file.quarter === "10K"
      );
    } else {
      // Find the index of the next file with the same quarter
      nextIndex = filesList?.findIndex(
        (file, index) =>
          index > fileIndex && quarterConverter(file?.quarter) === quarterValue
      );
      firstFile = filesList?.find(
        (file, index) =>
          index === fileIndex &&
          quarterConverter(file?.quarter) === quarterValue
      );
      secondFile = filesList?.find(
        (file, index) =>
          index > fileIndex && quarterConverter(file?.quarter) === quarterValue
      );
    }

    const firstFile_id = firstFile?.id;
    const secondFile_id = secondFile?.id;

    const selectedFile = await allSelectedFiles?.find(
      (files) =>
        files.prev === fileIndex &&
        files.next === nextIndex &&
        files.ticker === companyTicker
    );

    /* 
    const  { prev, next, score, resUrl, ticker, time } = selectedFile
     this is for avoiding compare files API calling  if we have these value from redux
     then we don't call API and <= 59 check is for after this time file url is removed
     from server then we can call Api again 
     */
    if (
      selectedFile?.ticker === companyTicker &&
      Math.floor((Date.now() - selectedFile?.time) / 60000) <= 59
    ) {
      setSelectedFiles([selectedFile?.prev, selectedFile?.next]);
      dispatch(fileId(selectedFile?.firstFileId));
      setFirstFileDetails(filesList[selectedFile?.prev]);
      setSecondFileDetails(filesList[selectedFile?.next]);
      setQuarterValue(selectedFile?.quarterValue);
      setFilesCta(selectedFile?.cta);
      dispatch(
        setDetailValues({
          firstFile: filesList[selectedFile?.prev],
          secondFile: filesList[selectedFile?.next],
          quarter: selectedFile?.quarterValue,
        })
      );
      // setFilesDetails({
      //   firstFile: filesList[selectedFile?.prev],
      //   secondFile: filesList[selectedFile?.next],
      //   quarter: selectedFile?.quarterValue,
      // });
      handlePdfFileTitle(
        filesList[selectedFile?.prev],
        filesList[selectedFile?.next]
      );
      setSimilarityScore(selectedFile?.score);
      setFileUrl(selectedFile?.resUrl);
      setLoader(true);
      return convertHtmlToReact(selectedFile?.resUrl);
    }

    if (selectedIndex === -1) {
      setSelectedFiles([fileIndex, nextIndex]);
    } else {
      setSelectedFiles([fileIndex, nextIndex]);
    }

    dispatch(fileId(firstFile_id));
    setFirstFileDetails(firstFile);
    setSecondFileDetails(secondFile);
    dispatch(
      setDetailValues({
        firstFile,
        secondFile,
        quarter: quarterValue,
      })
    );
    // setFilesDetails({
    //   firstFile,
    //   secondFile,
    //   quarter: quarterValue,
    // });

    handlePdfFileTitle(firstFile, secondFile);
    // const filesScoreRes = await DiffCheckerService.filesScore(
    //   companyTicker,
    //   quarterValue,
    //   firstFile_id,
    //   secondFile_id
    // );
    // setSimilarityScore(filesScoreRes?.first_similarity); May be use later
    setLoader(true);
    const res = await DiffCheckerService.compareFiles(
      companyTicker,
      quarterValue,
      firstFile_id,
      secondFile_id
    );
    let compareFileUrl = res?.data;
    let compareFilesCta = res?.files;
    dispatch(
      filesDetail({
        prev: fileIndex,
        next: nextIndex,
        // score: filesScoreRes?.first_similarity,
        resUrl: compareFileUrl,
        ticker: companyTicker,
        time: Date.now(),
        firstFileId: firstFile_id,
        quarterValue: quarterValue,
        cta: compareFilesCta,
      })
    );
    setFileUrl(res);
    setFilesCta(res?.files);

    await convertHtmlToReact(res?.data);
  };

  const handleViewDocument = (fileUrl, fileId) => {
    window.open(fileUrl, `documentTab_${fileId}`, "noopener");
  };

  return (
    <div className="row">
      <div className="col-md-1">
        <div className="select-file-container">
          <div className="select-file-main">
            <span className="select-file-txt">Select File</span>
            <div className="file-list">
              <ul className="list">
                {filesList?.map((files, index) => {
                  // To disable last 10K and 10Q in an array
                  let last10KIndex = -1;
                  let last10QIndex = -1;
                  filesList?.forEach((item, index) => {
                    if (item.quarter === "10K") {
                      last10KIndex = index;
                    } else if (
                      item.quarter === "Quarter-1" ||
                      item.quarter === "Quarter-2" ||
                      item.quarter === "Quarter-3"
                    ) {
                      last10QIndex = index;
                    }
                  });
                  // To disable last value in an array
                  const lastIndex = filesList?.length - 1;

                  const isDisabled =
                    index === last10KIndex ||
                    index === lastIndex ||
                    index === last10QIndex;

                  const isSelected = selectedFiles[0] === index;
                  const isNextSelected =
                    selectedFiles.length === 2 && selectedFiles[1] === index;
                  const fileClass = isSelected
                    ? "list-items selected"
                    : isNextSelected
                    ? "list-items light-grey"
                    : "list-items";

                  return (
                    <li
                      className={`${fileClass} ${isDisabled ? "disabled" : ""}`}
                      key={index}
                      onClick={() => handleFileClick(index, files?.quarter)}
                    >
                      <p className="list-value">
                        {files?.quarter.includes("Quarter") ? "10Q" : "10K"}
                      </p>
                      <span className="list-date">
                        {dateMMMddyyyy(files?.date)}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-11">
        <div className="compare-files-container">
          <div className="comparing-files-main">
            <div className="d-flex  align-items-center">
              <span className="compare-text">Comparing</span>

              <div className="file-details">
                <div
                  className="first-file-detail"
                  onClick={() =>
                    handleViewDocument(filesCta?.first_file_url, "firstFile")
                  }
                >
                  <div className="filing-main">
                    <span className="filing-type">
                      {firstFileDetails?.quarter.includes("Quarter")
                        ? `10Q ${firstFileDetails?.fileyear}`
                        : firstFileDetails?.fileyear
                        ? `10K ${firstFileDetails?.fileyear}`
                        : `10K - `}
                    </span>
                    <span className="filing-date">
                      {firstFileDetails?.date &&
                        dateMMMddyyyy(firstFileDetails?.date)}
                    </span>
                  </div>
                </div>
                <div
                  className="second-file-detail"
                  onClick={() =>
                    handleViewDocument(filesCta?.second_file_url, "secondFile")
                  }
                >
                  <div className="filing-main">
                    <span className="filing-type">
                      {secondFileDetails?.quarter.includes("Quarter")
                        ? `10Q ${secondFileDetails?.fileyear}`
                        : secondFileDetails?.fileyear
                        ? `10K ${secondFileDetails?.fileyear}`
                        : `10K - `}
                    </span>
                    <span className="filing-date">
                      {secondFileDetails?.date &&
                        dateMMMddyyyy(secondFileDetails?.date)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ul className="inline-ul legend-diff-ul">
              <li>
                <div className="d-flex align-items-center">
                  <div className="box-legend box-green"></div>
                  <div className="p4">
                    <p>Added</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="box-legend box-pink"></div>
                  <div className="p4">
                    <p>Removed</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          {loader ? (
            <RSLoader loading={loader} showLoaderText={true} />
          ) : (
            <div
              id="content-container"
              className="compare_file_content"
              dangerouslySetInnerHTML={{ __html: compareFilesUrl }}
              ref={innerRef}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DiffChecker;
