import { postRequest } from "./interfaces/axios-methods";
import { FLASH_REPORT_URL, GET_FLASH_REPORT_URL } from "./interfaces/api-urls";

async function flashReport(result) {
  try {
    const response = await postRequest(FLASH_REPORT_URL, result);
    return await response?.data?.data;
  } catch (error) {
    return await error?.response?.data;
  }
}

async function getFlashReport(result) {
  try {
    const response = await postRequest(GET_FLASH_REPORT_URL, result);
    return await response;
  } catch (error) {
    return await error?.response?.data;
  }
}

const FlashReportService = {
  flashReport,
  getFlashReport,
};

export default FlashReportService;
