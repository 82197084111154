import React from "react";
import { images } from "../../../helpers/images";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-content">
              <p>
                © {new Date().getFullYear()}. All rights reserved | Privacy
                Policy
              </p>
              {/* <div className="media-icons">
                <a
                  href="https://www.linkedin.com/company/96959468/admin/feed/posts/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={images.Linkedin}
                    alt="Linkedin"
                    className="mr-12 img-fluid"
                  />
                </a>
                <a
                  href="https://twitter.com/d__risk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={images.Twitter}
                    alt="Twitter"
                    className="img-fluid"
                  />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
