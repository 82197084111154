import { components } from "react-select";
import { images } from "../helpers/images";
//Filing static list
const filings = [
  {
    label: "10K",
    value: "10K",
  },
  {
    label: "10Q",
    value: "10Q",
  },
];

const sentiments = [
  {
    label: "Positive",
    value: "positive",
  },
  {
    label: "Neutral",
    value: "neutral",
  },
  {
    label: "Negative",
    value: "negative",
  },
];

// Risk Analysis table header
const headersDataBody = ["Stats Name", "Curr File", "Ind Median", "Diff", ""];

const optionsYear = [
  { value: 2, label: "2 Years" },
  { value: 5, label: "5 Years" },
  { value: 10, label: "10 Years" },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#1E2449" : "#1E2449",
    backgroundColor: state.isSelected ? "transparent" : "transparent",
    fontWeight: state.isSelected ? "bold" : "400",
    cursor: state.isDisabled ? "not-allowed !important" : "pointer",
  }),
};

// Sentiment Analysis Options Filing
const optionsFiling = [
  { value: "10-Q", label: "10Q" },
  { value: "10-K", label: "10K" },
];

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div>{props?.data?.label}</div>
    </components.Option>
  );
};

// Flash Report Sentiments
const sentimentsData = [
  {
    value: 1,
    text: "Positive",
    icon: images.PositiveIcon,
  },
  {
    value: 2,
    text: "Negative",
    icon: images.NegativeIcon,
  },
  {
    value: 3,
    text: "Neutral",
    icon: images.NeutralIcon,
  },
];

export {
  filings,
  headersDataBody,
  optionsYear,
  customStyles,
  optionsFiling,
  Option,
  sentimentsData,
  sentiments,
};
