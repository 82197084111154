import TextOverlayToolTip from "../components/common/TextOverLayToolTip/TextOverLayToolTip";
import { dateMMMddyyyy } from "./utility-functions";

export const CompanyColumns = [
  {
    Header: "Name",
    accessor: "name", // accessor is the "key" in the data that is returned from API
    Cell: ({ row: { original } }) => (
      <TextOverlayToolTip textString={original?.name} charactersLength={40} />
    ),
  },
  {
    Header: "Highlights",
    accessor: "Highlights",
    Cell: ({ row: { original } }) =>
      original?.flash_sentiments &&
      ["positive", "negative", "neutral"].includes(
        original.flash_sentiments
      ) ? (
        <span className={`circle ${original.flash_sentiments}`}></span>
      ) : null,
  },
  {
    Header: "Ticker",
    accessor: "ticker",
  },
  {
    Header: "Date",
    accessor: "filedate",
    Cell: ({ row: { original } }) => <>{dateMMMddyyyy(original?.filedate)}</>,
  },
  {
    Header: "Sector",
    accessor: "sector",
    Cell: ({ row: { original } }) => (
      <TextOverlayToolTip textString={original?.sector} charactersLength={25} />
    ),
  },
  {
    Header: "Industry",
    accessor: "industry",
    Cell: ({ row: { original } }) => (
      <TextOverlayToolTip
        textString={original?.industry}
        charactersLength={45}
      />
    ),
  },
  {
    Header: "Filing",
    accessor: "quarter",
    Cell: ({ row: { original } }) =>
      original.quarter === "Quarter-1" ||
      original.quarter === "Quarter-2" ||
      original.quarter === "Quarter-3"
        ? "10Q"
        : "10K",
  },
];
