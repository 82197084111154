import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import React from "react";

const override = css`
  display: block;
`;
const RSLoader = (props) => {
  return (
    <div
      className="sweet-loading"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 999999,
        width: "100vw",
        height: "100vh",
        backgroundColor: "#000",
        opacity: 0.5,
        display: props.loading ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <BeatLoader
        color={"#def0fa"}
        loading={props.loading}
        size={15}
        speedMultiplier="1"
        css={override}
      />
      {props.showLoaderText ? (
        <div style={{ textAlign: "center", color: "#def0fa" }}>
          <p>Usually takes less than a minute</p>
        </div>
      ) : (
        <br />
      )}
    </div>
  );
};

export default RSLoader;
