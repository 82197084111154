import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { images } from "../../helpers/images";
import TabsHeading from "../../components/ui/tabs/TabsHeading";
import DiffChecker from "../../components/common/DiffChecker/DiffChecker";
import RiskAnalysis from "../../components/common/RiskAnalysis/RiskAnalysis";
import SentimentAnalysis from "../../components/common/SentimentAnalysis/SentimentAnalysis";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import TextOverlayToolTip from "../../components/common/TextOverLayToolTip/TextOverLayToolTip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FlashReport from "../../components/common/FlashReport/FlashReport";
import "./CompanyDetails.scss";

const CompanyDetails = () => {
  const { companyName, companyTicker } = useParams();
  const [fileUrl, setFileUrl] = useState("");
  const [docTitle, setDocTitle] = useState({});
  const [flashReportLink, setFlashReportLink] = useState("");
  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);
  const componentRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const { trackEvent } = useGoogleAnalytics();

  useEffect(() => {
    // Track a pageview with a custom page name
    trackEvent(null, null, "pageview", "Company Details");
  }, []);

  useEffect(() => {
    // Get the current pathname
    const currentPath = window.location.href;
    // Remove the "admin" segment from the pathname
    const updatedPath = currentPath.replace("/admin", "");
    // Update the URL with the modified pathname
    const flashUrl = updatedPath + "/" + flashReportLink;
    setValue(flashUrl);
  }, [flashReportLink]);

  const handleClick = () => {
    history.push("/recent-filing");
    trackEvent("Recent FIling Button", "Click", "event");
  };

  const handleViewDocument = (fileUrl) => {
    // Track a custom event
    trackEvent("View File Button", "Click", "event");
    window.open(fileUrl, "noopener");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:
      companyName + " " + docTitle?.prev?.date + " To " + docTitle?.next?.date,
  });

  const onCopy = React.useCallback(() => {
    setCopied(true);
    toast.success("Link Copied!");
  }, []);

  return (
    <section className="company-details">
      <div className="row">
        <div className="col-12">
          <div className="details-header">
            <div className="back-btn">
              <Link to="#" className="backarrow" onClick={handleClick}>
                <img
                  src={images.LeftChevron}
                  className="img-fluid"
                  alt="back"
                />
                {companyName}
              </Link>
            </div>
            <div className="rs-tabs detail-tabs">
              <Nav as="ul" id="vertical-tabs" className="nav-tabs">
                <TabsHeading
                  datatestid="flash-report-tab"
                  title="Highlights"
                  link={`/company-details/flash-report/${companyName}/${companyTicker}`}
                  active={
                    location.pathname.includes("flash-report") ? "active" : ""
                  }
                />
                <TabsHeading
                  datatestid="diff-checker-tab"
                  title="Diff Checker"
                  link={`/company-details/diff-checker/${companyName}/${companyTicker}`}
                  active={
                    location.pathname.includes("diff-checker") ? "active" : ""
                  }
                />

                <TabsHeading
                  datatestid="risk-analysis-tab"
                  title="Risk Analysis"
                  link={`/company-details/risk-analysis/${companyName}/${companyTicker}`}
                  active={
                    location.pathname.includes("risk-analysis") ? "active" : ""
                  }
                />
                <TabsHeading
                  datatestid="sentiment-analysis-tab"
                  title="Sentiment Analysis"
                  link={`/company-details/sentiment-analysis/${companyName}/${companyTicker}`}
                  active={
                    location.pathname.includes("sentiment-analysis")
                      ? "active"
                      : ""
                  }
                />
              </Nav>
            </div>
            <div className="actions-content">
              {location.pathname.includes("admin") && flashReportLink && (
                <div className="action-parent">
                  <TextOverlayToolTip
                    icon={true}
                    title="Copy to clipboard"
                    src={images.FlashReportLink}
                    onCopy={onCopy}
                    text={value}
                    flashReport={true}
                  />
                </div>
              )}
              {!location.pathname.includes("flash-report") && (
                <div
                  className="action-parent"
                  onClick={() => handleViewDocument(fileUrl)}
                >
                  <TextOverlayToolTip
                    icon={true}
                    title="View Document"
                    src={images.EyeIcon}
                  />
                </div>
              )}

              {location.pathname.includes("diff-checker") && (
                <div className="action-parent" onClick={handlePrint}>
                  <TextOverlayToolTip
                    icon={true}
                    title="Download Pdf"
                    src={images.DownloadIcon}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          location.pathname.includes("flash-report")
            ? "company-details-container-flash-report"
            : "company-details-container"
        }
      >
        {location.pathname.includes("diff-checker") && (
          <DiffChecker
            companyTicker={companyTicker}
            setFileUrl={setFileUrl}
            setDocTitle={setDocTitle}
            innerRef={componentRef}
          />
        )}
        {location.pathname.includes("flash-report") && (
          <FlashReport
            companyTicker={companyTicker}
            setFlashReportLink={setFlashReportLink}
          />
        )}

        {location.pathname.includes("risk-analysis") && (
          <RiskAnalysis companyTicker={companyTicker} setFileUrl={setFileUrl} />
        )}
        {location.pathname.includes("sentiment-analysis") && (
          <SentimentAnalysis
            companyTicker={companyTicker}
            setFileUrl={setFileUrl}
          />
        )}
      </div>
    </section>
  );
};

export default CompanyDetails;
