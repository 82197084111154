import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl } from "../../enviroment/environment";
import { setLoading } from "../../actions/loading";

var axiosClient = axios.create({});
axiosClient.defaults.baseURL =
  //  "https://risk-shark.troonhosting.com/api/";
  apiUrl;

//To avoid duplicate error message
const notify = (message, toastId) => {
  toast.error(message, {
    autoClose: 5000,
    toastId,
  });
};

const SetupInterceptors = (store) => {
  const errorHandler = (error) => {
    // console.log(error?.response?.data?.error?.message);
    switch (error?.status || error.response.status) {
      case 200:
        if (error?.response?.status === 400) {
          toast.error(error?.data?.message);
        }
        break;
      case 400:
        if (error?.response?.data?.error?.message) {
          toast.error(error?.response?.data?.error?.message);
        } else {
          toast.error(error?.response?.data?.error);
        }

        break;
      case 401:
        notify(error?.response?.data?.error, error.toString());
        break;
      case 403:
        toast.error(error?.response?.data?.error);
        break;
      case 404:
        //show an error message incase content is missing
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          //show an error message incase api not found
          toast.error(error?.response?.statusText);
        }
        break;
      case 415:
        toast.error(error?.response?.statusText);
        break;
      case 500:
        toast.error("Internal Server Error");
        break;

      default:
    }
  };

  // Add a request interceptor
  axiosClient.interceptors.request.use(
    (config) => {
      if (!window.location.pathname.includes("diff-checker")) {
        store.dispatch(setLoading(true));
      }

      // const jwt = store.getState()?.auth?.user?.token;

      // config.headers.Authorization = jwt ? `Token ${jwt}` : "";

      return config;
    },
    (error) => {
      store.dispatch(setLoading(false));
      return error;
    }
  );

  axiosClient.interceptors.response.use(
    (response) => {
      store.dispatch(setLoading(false));
      errorHandler(response);
      return response;
    },
    (error) => {
      store.dispatch(setLoading(false));
      if (error.response.status === 401) {
        notify(error?.response?.data?.error, error.toString());

        // window.location.reload();
      } else {
        errorHandler(error);
      }
      return Promise.reject(error);
    }
  );
};

export { SetupInterceptors, axiosClient };
