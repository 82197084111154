export const apiUrl = process.env.REACT_APP_API_URL;

let url = window.location.href;
url = apiUrl;

export const Env = {
  BasicUrl: url,
  CompaniesApiUrl: url + "companies/",
  FilingsApiUrl: url + "riskanalysis/",
  GroupsApiUrl: url + "groups/",
  UsersApiUrl: url + "users/",
};
