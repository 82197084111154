// FilterOption.jsx
import React from "react";
import { Accordion, Form } from "react-bootstrap";

const FilterOptions = ({
  name,
  title,
  eventKey,
  selectedValues,
  handleClearSelection,
  options,
  handleFilterChange,
  showSummary,
  customComponent, // New prop for a custom component
  addClassName,
  labelProperty, // New prop for dynamic label property
  valueProperty, // New prop for dynamic value property
  checkedProperty, // New prop for dynamic checked property
  labelComponent, // New prop for dynamic label component
}) => (
  <Accordion.Item eventKey={eventKey} className="item">
    <Accordion.Header>{title}</Accordion.Header>
    {showSummary && selectedValues.length > 0 && (
      <div className="selection-summary">
        <span className="length-text">{selectedValues.length} selected</span>
        <button
          type="button"
          className="btn btn-primary clear-btn"
          onClick={handleClearSelection}
        >
          Clear
        </button>
      </div>
    )}
    <Accordion.Body>
      <div className="checkbox-container">
        {customComponent && <div>{customComponent}</div>}
        {options.map((option, index) => (
          <Form.Check
            key={index}
            type="checkbox"
            name={name}
            label={
              labelComponent
                ? labelComponent(option[labelProperty])
                : option[labelProperty]
            }
            value={option[valueProperty]}
            checked={selectedValues.includes(option[checkedProperty])}
            onChange={handleFilterChange}
            className={addClassName && option.value.toLowerCase()} // Add className conditionally
          />
        ))}
      </div>
    </Accordion.Body>
  </Accordion.Item>
);

export default FilterOptions;
