const COMPANIES_API_URL = "companies/";
const GET_FILES_API_URL = "diffchecker/getfiles/";
const COMPARE_FILES_API_URL = "difference/compare_files";
const GET_STOCKS_API_URL = "stocks/getstocks/";
const GET_COMPANY_MEDIAN_RISK_ANALYSIS_API_URL =
  "riskanalysis/getcompanymedianrisksanalysis/";
const GET_SENTIMENTS_API_URL = "riskanalysis/getcompanysentimentsanalysis/";
const GET_QUARTERS_API_URL = "riskanalysis/getfilequarters/";
const GET_ALL_FILES_MEDIAN_PRESENTIMENTS =
  "riskanalysis/getallfilemedianpresetiments/";
const FILES_SCORE_API_URL = "difference/files_score";
const FLASH_REPORT_URL = "difference/save_flash_report";
const GET_FLASH_REPORT_URL = "difference/get_flash_report";

export {
  COMPANIES_API_URL,
  GET_FILES_API_URL,
  COMPARE_FILES_API_URL,
  GET_STOCKS_API_URL,
  GET_COMPANY_MEDIAN_RISK_ANALYSIS_API_URL,
  GET_SENTIMENTS_API_URL,
  GET_QUARTERS_API_URL,
  GET_ALL_FILES_MEDIAN_PRESENTIMENTS,
  FILES_SCORE_API_URL,
  FLASH_REPORT_URL,
  GET_FLASH_REPORT_URL,
};
