import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import Chart from "react-google-charts";
import {
  optionsFiling,
  optionsYear,
  customStyles,
  Option,
} from "../../../helpers/constants";
import { sentimentAnalysisChartOptions } from "./SentimentAnalysisCharts";
import useGoogleAnalytics from "../../../hooks/useGoogleAnalytics";
import useSentimentsData from "../../../hooks/useSentimentsData";
import SentimentAnalysisTable from "./SentimentAnalysisTable";
import "./SentimentAnalysis.scss";


const SentimentAnalysis = ({ companyTicker, setFileUrl }) => {
  const { companyName } = useParams();
  const [selectedFiling, setSelectedFiling] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const { trackEvent } = useGoogleAnalytics();

  const {
    getSentimentsOnFilters,
    getQuarters,
    getFileSentiments,
    chartData,
    optionsQuarter,
    defaultQuarter,
    setDefaultQuarter,
    industryName,
    sentiments,
    prevDoc,
    indAvg,
    prevSentiments,
    quarterConverter,
  } = useSentimentsData();

  useEffect(() => {
    if (optionsYear?.length > 0 && companyTicker) {
      setSelectedFiling({
        value: optionsFiling[0].value,
        label: optionsFiling[0].label,
      });
      setSelectedYear({
        value: optionsYear[2]?.value,
        label: optionsYear[2]?.label,
      });
      getSentimentsOnFilters(companyTicker, optionsYear[2]?.value, companyName);
      getQuarters(
        companyTicker,
        optionsFiling[0]?.value,
        setFileUrl,
        selectedFiling
      );
    }
    // Track a pageview with a custom page name
    trackEvent(null, null, "pageview", "Sentiment Analysis");
  }, []);

  const handleSetOptionsFiling = (e) => {
    setSelectedFiling({ value: e?.value, label: e?.label });
    getQuarters(companyTicker, e?.value, setFileUrl);
  };

  const handleSetOptionsQuarter = (e) => {
    let quarter = quarterConverter(e?.value);
    let quarterDate = moment(e?.subLabel).format("MMM D, YYYY");
    setDefaultQuarter({
      value: quarter + ": " + quarterDate,
      label: quarter + ": " + quarterDate,
    });
    getFileSentiments(e, setFileUrl, selectedFiling);
  };

  const handleSetOptionsYear = (e) => {
    setSelectedYear({ value: e?.value, label: e?.label });
    getSentimentsOnFilters(companyTicker, e?.value, companyName);
  };

  return (
    <div className="sentiment-analysis-main">
      <div className="row">
        <div className="col-lg-6 d-md-flex flex-md-column mb-4 mb-lg-0 pe-4">
          <div className="sa-word-count-content">
            <div className="row align-items-center">
              <div className="col-sm-5 col-12 mb-4 mb-xl-0">
                <h2>Word Counts</h2>
              </div>
              <div className="col-sm-7 col-12">
                <div className="d-flex justify-content-end">
                  <div className="form-group me-4">
                    <div className="form-controls react-drop react-drop-small mw-80">
                      <Select
                        value={selectedFiling}
                        onChange={handleSetOptionsFiling}
                        options={optionsFiling}
                        styles={customStyles}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-controls react-drop react-drop-small mw-172">
                      <Select
                        value={defaultQuarter}
                        onChange={handleSetOptionsQuarter}
                        options={optionsQuarter}
                        styles={customStyles}
                        isSearchable={false}
                        components={{ Option }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 rs-hor-tabs">
              <ul className="nav nav-pills mb-3 inline-ul">
                <li>
                  <button
                    className={`nav-link active ${
                      !prevDoc && indAvg ? "active" : ""
                    }`}
                    id="pills-ind-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-ind"
                    type="button"
                    role="tab"
                    aria-controls="pills-ind"
                    aria-selected={`true ${!prevDoc && indAvg ? "active" : ""}`}
                  >
                    Industry Median
                  </button>
                </li>
                {prevDoc && (
                  <li>
                    <button
                      className="nav-link"
                      id="pills-prev-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-prev"
                      type="button"
                      role="tab"
                      aria-controls="pills-prev"
                      aria-selected="false"
                    >
                      Previous Filing
                    </button>
                  </li>
                )}
              </ul>
            </div>
            <div className="tab-content">
              <div
                className={`tab-pane fade show active ${
                  !prevDoc && indAvg ? "active" : ""
                }`}
                id="pills-ind"
                role="tabpanel"
                aria-labelledby="pills-ind-tab"
              >
                <div className="row mt-24">
                  <div className="col-12">
                    <ul className="inline-ul legend-diff-ul mb-4">
                      <li>
                        <div className="d-flex align-items-center">
                          <div className="box-legend box-blue"></div>
                          <div className="p4">
                            <p>Current Stats</p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="d-flex align-items-center">
                          <div className="box-legend box-yellow"></div>
                          <div className="p4">
                            <p>
                              Industry Median{" "}
                              {industryName && "(" + industryName + ")"}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <SentimentAnalysisTable
                    sentimentsData={sentiments}
                    avgOrMedian="median"
					file="file"
                  />
                </div>
              </div>

              {prevDoc && (
                <div
                  className="tab-pane fade"
                  id="pills-prev"
                  role="tabpanel"
                  aria-labelledby="pills-prev-tab"
                >
                  <div className="row mt-24">
                    <div className="col-12">
                      <ul className="inline-ul legend-diff-ul mb-4">
                        <li>
                          <div className="d-flex align-items-center">
                            <div className="box-legend box-blue"></div>
                            <div className="p4">
                              <p>Current Stats</p>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="d-flex align-items-center">
                            <div className="box-legend box-yellow"></div>
                            <div className="p4">
                              <p>Previous Filing</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <SentimentAnalysisTable
                      sentimentsData={prevSentiments}
                      avgOrMedian="avg"
					  file="file"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-6 d-flex flex-md-column ps-4">
          <div className="sa-word-count-content">
            <div className="row align-items-center justify-content-between">
              <div className="col-sm-8 mb-4 mb-sm-0">
                <h2>Trend</h2>
              </div>
              <div className="col-sm-4 toRight mw-140">
                <div className="form-group">
                  <div className="form-controls react-drop react-drop-small">
                    <Select
                      value={selectedYear}
                      onChange={handleSetOptionsYear}
                      options={optionsYear}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-16">
              <div className="col-12">
                <ul className="inline-ul legend-diff-ul">
                  <li>
                    <div className="d-flex align-items-center">
                      <div className="box-legend box-blue"></div>
                      <div className="p4">
                        <p>{companyName ? companyName + "- File" : ""}</p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="d-flex align-items-center">
                      <div className="box-legend box-yellow"></div>
                      <div className="p4">
                        <p>
                          Industry Avg{" "}
                          {industryName && "(" + industryName + ")"}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="chart-wrap">
              {chartData?.length > 1 && (
                <Chart
                  chartType="LineChart"
                  data={chartData}
                  options={sentimentAnalysisChartOptions}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentimentAnalysis;
