import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  details: {},
};

const flashReportSlice = createSlice({
  name: "setDetailValues",
  initialState,
  reducers: {
    setDetailValues: (state, action) => {
      state.details = action.payload;
    },
  },
});

export default flashReportSlice.reducer;
export const { setDetailValues } = flashReportSlice.actions;
