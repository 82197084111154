import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
//
const TabsHeading = (props) => {
  const { title, link, datatestid, active } = props;
  return (
    <Nav.Item className="nav-item text-center" as="li">
      <Link
        data-testid={datatestid}
        to={link}
        as="button"
        className={`nav-link ${active}`}
      >
        <span className="tabname">{title}</span>
      </Link>
    </Nav.Item>
  );
};

export default TabsHeading;
