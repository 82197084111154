import React from "react";
import { Form, Dropdown } from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";

const SentimentCommentsSection = ({
  sentimentType,
  flashReportForm,
  activeCommentIndex,
  handleAnchorClick,
  handleEditComments,
  handleDeleteComments,
  sentimentsData,
  handleChange,
  images,
  editComment,
  handleSubmitEditComment,
  validationSchema,
  characterCount,
  handleClearEditForm,
  getComments,
  location,
}) => {
  // Generate the key for accessing the corresponding sentiment icon
  const iconKey = `${sentimentType
    .charAt(0)
    .toUpperCase()}${sentimentType.slice(1)}Icon`;
  // Custom styles for the react-select dropdown
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#1E2449" : "#1E2449",
      backgroundColor: state.isSelected ? "transparent" : "transparent",
      fontWeight: state.isSelected ? "bold" : "400",
      cursor: state.isDisabled ? "not-allowed !important" : "pointer",
    }),
  };
  // Get the sentiment comments based on the sentiment type
  const sentimentComments =
    getComments?.flash_sentiments?.[sentimentType] || [];
  return (
    <>
      {sentimentComments?.map((comment, index) => {
        const isEditing =
          flashReportForm[`${sentimentType}CommentId`] === comment?.id;
        return (
          <div key={comment?.id} className="mb-16 comments-container">
            <div
              className={`comments-content ${
                activeCommentIndex === comment?.id ? "active-comment" : ""
              }`}
              onClick={(event) => handleAnchorClick(event, comment?.id)}
            >
              <div className="show-comments">
                <img
                  src={images[iconKey]}
                  className="mr-10 img-fluid"
                  alt="sentiments"
                />
                <p className="comment-title">{comment?.title}</p>
              </div>
              {/* Dropdown for edit and delete options (visible based on location) */}
              {location && (
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={images.VerticalDots} alt="verticalDots" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      data-testid="editbtn"
                      onClick={() =>
                        handleEditComments(comment?.id, sentimentType)
                      }
                    >
                      <img
                        src={images.EditIcon}
                        className="mr-10 img-fluid"
                        alt="edit"
                      />
                      Edit
                    </Dropdown.Item>

                    <Dropdown.Item
                      data-testid="deletebtn"
                      onClick={() =>
                        handleDeleteComments(
                          comment?.id,
                          index,
                          sentimentType,
                          comment?.dataSpanId
                        )
                      }
                    >
                      <img
                        src={images.DeleteIcon}
                        className="mr-10 img-fluid"
                        alt="delete"
                      />
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            {/* Display edit form when editing is enabled */}
            {flashReportForm[`${sentimentType}Comment`] && isEditing && (
              <Formik
                enableReinitialize
                initialValues={{
                  sentiments: editComment
                    ? editComment?.icon === "positive"
                      ? sentimentsData[0]
                      : editComment?.icon === "negative"
                      ? sentimentsData[1]
                      : editComment?.icon === "neutral"
                      ? sentimentsData[2]
                      : sentimentsData[0]
                    : sentimentsData[0],
                  title: editComment ? editComment?.title : "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmitEditComment}
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  handleSubmit,
                  resetForm,
                  dirty,
                }) => (
                  <Form
                    onSubmit={(e) =>
                      handleSubmit(e, {
                        resetForm,
                        setFieldValue,
                      })
                    }
                    noValidate
                    className="sentiments-form mb-16"
                  >
                    <div className="form-group mb-16">
                      <div className="form-controls react-drop">
                        <Select
                          name="sentiments"
                          options={sentimentsData}
                          value={values?.sentiments}
                          onChange={(e) => handleChange(setFieldValue, e)}
                          getOptionLabel={(e) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={e.icon}
                                className="img-fluid"
                                alt="sentiments"
                                width={20}
                                height={20}
                              />

                              <span
                                style={{
                                  marginLeft: 5,
                                  fontSize: "1.4rem",
                                }}
                              >
                                {e.text}
                              </span>
                            </div>
                          )}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                    <Form.Group className="form-group">
                      <div className="form-controls">
                        <Form.Control
                          name="title"
                          bsPrefix=" "
                          placeholder="Add Your Title"
                          type="text"
                          value={values.title || ""}
                          onChange={(e) => handleChange(setFieldValue, e)}
                          className="sentiments-title"
                        />
                        {errors.title && touched.title && (
                          <p className="error">{errors.title}</p>
                        )}
                      </div>
                    </Form.Group>
                    <p className="character-limit">
                      Character Limit: {characterCount}/100
                    </p>
                    <div className="mt-20 d-flex flex-column flex-sm-row align-items-center justify-content-end">
                      <button
                        type="button"
                        data-testid="clearbtn"
                        className="btn clear-btn"
                        onClick={() =>
                          handleClearEditForm(resetForm, sentimentType)
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        data-testid="clearbtn"
                        className={`btn btn-primary mb-3 mb-sm-0 btn-outline ${
                          dirty ? "" : "disabled"
                        }`}
                        disabled={!dirty}
                      >
                        Edit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        );
      })}
    </>
  );
};

export default SentimentCommentsSection;
