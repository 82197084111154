// useGoogleAnalytics.js
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID; // YOUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const useGoogleAnalytics = () => {
  const [pageName, setPageName] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    // Second Option
    // ReactGA.send({
    // 	hitType: 'pageview',
    // 	page: window.location.pathname,
    // 	title: 'Home Page',
    // });
  }, []);

  useEffect(() => {
    if (pageName) {
      ReactGA.send({ hitType: "pageview", page: pageName });
    }
  }, [pageName]);

  /**
   * Loaded Option
   * The hitType parameter determines whether to track an event or a pageview,
   * and the pageName parameter is only used when hitType is set to 'pageview'.
   * */
  const trackEvent = (category, action, hitType = "event", pageName = null) => {
    if (hitType === "event") {
      ReactGA.event({
        category,
        action,
      });
    } else if (hitType === "pageview" && pageName) {
      setPageName(pageName);
    }
  };

  return { trackEvent };
};

export default useGoogleAnalytics;
