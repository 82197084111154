import React from "react";
import Select, { components } from "react-select";
import { Form } from "react-bootstrap";

const allOption = { label: "Select all", value: "*" };

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-header icon-search"></span>
    </components.DropdownIndicator>
  );
};

// render a checkbox
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <Form.Check
          checked={props.isSelected}
          bsPrefix="form-checkbox"
          label={props.label}
          type="checkbox"
          onChange={() => null}
          className="custom-select-checkbox"
        />
        <span className="option-value">{props.value}</span>
      </components.Option>
    </div>
  );
};

// show only the "all selected" if all options are selected
const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

// show all selected when all options are selected
const MultiValue = (props) => {
  let labelToBeDisplayed = `${props.data.label} `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const ReactSelect = (props) => {
  const {
    options,
    handleChanges,
    errors,
    values,
    setFieldValue,
    name,
    placeholderText,
    isMulti,
    menuIsOpen,
    onMenuOpen,
    onMenuClose,
  } = props;

  const singleValue = options?.filter((obj) =>
    values[name]?.toString()
      ? obj?.value === values[name]
      : obj?.value === values
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "transparent" : "transparent",
      // color: state.isSelected ? "white" : "black",
    }),
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: "#FA637A", // Change this to your desired background color
      color: "white", // Change this to your desired text color
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white", // Change this to your desired text color
    }),
  };

  const onChange = (event, name, value, setFieldValue, label) => {
    if (isMulti) {
      if (value !== null && value.length > 0) {
        // select all options
        if (value[value.length - 1].value === allOption.value) {
          return handleChanges(name, [allOption, ...options], setFieldValue);
        }
        let result = [];

        if (value.length === options.length) {
          let isAvailable = value.some(
            (item) =>
              item.label === allOption.label && item.value === allOption.value
          );
          if (isAvailable) {
            result = value.filter((option) => option.value !== allOption.value);
          } else if (event.action === "select-option") {
            result = [allOption, ...options];
          }
          return handleChanges(name, result, setFieldValue, label);
        }
      }
    }
    return handleChanges(name, value, setFieldValue, label);
  };

  return (
    <>
      <Select
        {...props}
        options={
          isMulti
            ? options?.length > 0
              ? [allOption, ...options]
              : options
            : options
        }
        value={isMulti ? values?.[name] : singleValue}
        name={name}
        id={name}
        isMulti={isMulti}
        closeMenuOnSelect={isMulti ? false : true}
        menuIsOpen={menuIsOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        hideSelectedOptions={false}
        onChange={(selectedOption, event) =>
          onChange(
            event,
            name,
            isMulti ? selectedOption : selectedOption?.value,
            setFieldValue,
            isMulti ? selectedOption : selectedOption?.label
          )
        }
        placeholder={placeholderText}
        classNamePrefix="custom-select"
        components={
          isMulti
            ? { Option, ValueContainer, MultiValue }
            : { IndicatorSeparator: null, DropdownIndicator }
        }
        styles={isMulti ? customStyles : {}}
      />
      <p className="error">{errors[name]}</p>
    </>
  );
};

export default ReactSelect;
