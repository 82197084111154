import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  selectedFilesDetail: [],
};

const diffCheckerSlice = createSlice({
  name: "filesDetail",
  initialState,
  reducers: {
    filesDetail: (state, action) => {
      const currentState = current(state);
      const idAlreadyExist = currentState?.selectedFilesDetail?.findIndex(
        (state) => state.firstFileId === action.payload.firstFileId
      );

      if (idAlreadyExist !== -1) {
        state.selectedFilesDetail[idAlreadyExist] = action.payload;
      } else {
        state.selectedFilesDetail = [
          ...state.selectedFilesDetail,
          action.payload,
        ];
      }
    },
  },
});

export default diffCheckerSlice.reducer;
export const { filesDetail } = diffCheckerSlice.actions;
