import { postRequest } from "./interfaces/axios-methods";
import {
  GET_STOCKS_API_URL,
  GET_COMPANY_MEDIAN_RISK_ANALYSIS_API_URL,
} from "./interfaces/api-urls";

async function getStockPrice(ticker, range) {
  try {
    const response = await postRequest(GET_STOCKS_API_URL, {
      ticker: ticker,
      range: range,
    });
    return response;
  } catch (error) {
    return await error?.response?.data;
  }
}

async function getFilings(ticker, yearfilter) {
  try {
    const response = await postRequest(
      GET_COMPANY_MEDIAN_RISK_ANALYSIS_API_URL,
      {
        ticker: ticker,
        yearfilter: yearfilter,
      }
    );
    return response;
  } catch (error) {
    return await error?.response?.data;
  }
}

const FilingsService = {
  getStockPrice,
  getFilings,
};
export default FilingsService;
