import React from "react";
import ReactTooltip from "react-tooltip";
import { images } from "../../../helpers/images";
import TextOverlayToolTip from "../TextOverLayToolTip/TextOverLayToolTip";

const RiskAnalysisResults = ({ resultData, refresh, headersData }) => {
  return (
    <div className="ra-col-results">
      <span>Results</span>
      <div className="row mt-16">
        <div className="col-sm-3 mb-4 mb-sm-0">
          <div className="double-weight-text">
            <label htmlFor="">Price:</label>
            <br />
            <span className="price-filing-type">
              {resultData?.point?.options?.stockprice || resultData?.stockprice
                ? "$"
                : ""}
            </span>
            <span className="overflow-wrap price-filing-type">
              {resultData?.point?.options?.stockprice || resultData?.stockprice}
            </span>
          </div>
        </div>

        <div className="col-sm-5">
          <div className="double-weight-text">
            <label htmlFor="">Filing Type:</label>
            <br />

            <span className="price-filing-type">
              {resultData?.point?.options?.quarter == "10K" ||
              resultData?.quarter == "10K"
                ? `10K: ${
                    resultData?.point?.options?.dates || resultData?.dates
                  }`
                : ((resultData?.point?.options?.quarter === "Quarter-1" ||
                    resultData?.point?.options?.quarter === "Quarter-2" ||
                    resultData?.point?.options?.quarter === "Quarter-3") &&
                    `10Q: ${
                      resultData?.point?.options?.dates || resultData?.dates
                    }`) ||
                  ((resultData?.quarter === "Quarter-1" ||
                    resultData?.quarter === "Quarter-2" ||
                    resultData?.quarter === "Quarter-3") &&
                    `10Q: ${
                      resultData?.point?.options?.dates || resultData?.dates
                    }`)}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="double-weight-text">
          <span className="price-filing-type">Differences</span>
        </div>

        <div className="row row-table mt-8">
          <div className="col-12">
            <div className="similarity-score-text">
              <label htmlFor="">Similarity Score:&nbsp;</label>
              <span className="price-filing-type">
              {
                  resultData &&  <span className="price-filing-type">
                  <TextOverlayToolTip
                    textString= {resultData?.point?.options?.cosine_sim != -1 &&
                      resultData?.cosine_sim != -1
                        ? resultData?.point?.options?.cosine_sim ||
                          resultData?.cosine_sim
                        : "-"}
                    charactersLength={15}
                  />
                </span>
                }

                <span className="difference-date">
                  {resultData?.point?.options?.dates || resultData?.dates}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-32">
        <div className="p4 mb-16">
          <p>Sentiment Analysis</p>
        </div>
        {refresh && (
          <div className="table-wrap-mbl">
            <table className="table table-borderless table-ra">
              <thead>
                <tr>
                  {headersData?.map((key, index) => {
                    return <th key={index}>{key}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {(
                  resultData?.point?.options?.median_sentiment_data ||
                  resultData?.median_sentiment_data
                )?.map((data, index) => {
                  const { wordType, median, file, diff, words } = data; //destructuring

                  return (
                    <tr key={index}>
                      <td
                        data-tip
                        data-for={`registerTip ${index}`}
                        data-type="light"
                      >
                        {wordType}
                        <ReactTooltip
                          className="custom-tooltip-ra"
                          id={`registerTip ${index}`}
                          place="top"
                          clickable={true}
                          delayHide={150}
                        >
                          <h6>Sentiment Words List</h6>

                          <br />
                          <ul className="tooltip-ul">
                            {words?.map((data, index) => {
                              return <li key={index}>{data.word || "---"}</li>;
                            })}
                          </ul>

                          <br />
                        </ReactTooltip>
                      </td>

                      <td>{file ? file : "--"}</td>
                      <td>{median ? median : "--"}</td>
                      <td>{(Math.round(diff * 100) / 100).toFixed(1)}%</td>

                      {(wordType === "Positive" ||
                        wordType === "Strong Modal") && (
                        <td className={`${diff < 0 ? "red-down" : "green-up"}`}>
                          {diff < 0 ? (
                            <img
                              src={images.redIcon}
                              className="img-fluid"
                              alt="arrow"
                            />
                          ) : (
                            <img
                              src={images.greenIcon}
                              className="img-fluid"
                              alt="arrow"
                            />
                          )}
                        </td>
                      )}
                      {(wordType === "Negative" ||
                        wordType === "Litigious" ||
                        wordType === "Constraining" ||
                        wordType === "Uncertainty" ||
                        wordType === "Weak Modal") && (
                        <td className={`${diff > 0 ? "red-up" : "green-down"}`}>
                          {diff > 0 ? (
                            <img
                              src={images.redIcon}
                              className="img-fluid"
                              alt="arrow"
                            />
                          ) : (
                            <img
                              src={images.greenIcon}
                              className="img-fluid"
                              alt="arrow"
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskAnalysisResults;
