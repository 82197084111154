// Sentiment Analysis Chart Options
export const sentimentAnalysisChartOptions = {
  title: "",
  curveType: "function",
  legend: { position: "none" },
  pointSize: 5,
  colors: ["#FFB700", "#2B73D0"],
  plotOptions: {
    series: {
      threshold: 150,
    },
  },
  vAxis: {
    textPosition: "out",
    viewWindowMode: "explicit",
    viewWindow: {
      min: -2,
    },
    textStyle: {
      alignment: "center", // Align text to the start (left)
    },
    ticks: [
      { v: -2, f: "Lower\nRisk" },
      { v: 0, f: "Neutral" },
      { v: 2, f: "Higher\nRisk" },
    ],
  },
  hAxis: { showTextEvery: 1, slantedText: "true", slantedTextAngle: "75" },
  chartArea: {
    height: "100%",
    width: "100%",
    top: 20,
    left: 50,
    right: 0,
    bottom: 40,
  },
  minHeight: "50rem",
  height: "100%",
  width: "100%",
  lineWidth: 1,
  crosshair: {
    trigger: "focus",
    orientation: "vertical",
    color: "#6F738A",
    opacity: 0.3,
    focused: { color: "#6F738A" },
  },
  tooltip: { isHtml: true },
};
