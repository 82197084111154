import { getRequest } from "./interfaces/axios-methods";
import { COMPANIES_API_URL } from "./interfaces/api-urls";

async function getCompaniesList() {
  try {
    const response = await getRequest(COMPANIES_API_URL);
    if (response?.data) {
      return response?.data;
    }
  } catch (error) {
    return await error;
  }
}

const CompaniesService = {
  getCompaniesList,
};

export default CompaniesService;
