// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers";

// const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import combineReducers from "./reducers";


//// PERSISTANT STORAGE WITH AES ENCRYPTION

// The Advanced Encryption Standard (AES) is
// a symmetric block cipher chosen by the U.S.
// government to protect classified information.
// AES is implemented in software and hardware
// throughout the world to encrypt sensitive data.
// It is essential for government computer security,
// cybersecurity and electronic data protection.
const persistConfig = {
  key: "onboarding",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, combineReducers);

const configureStores = () => {
  let store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

  let persistor = persistStore(store);
  return { store, persistor };
};

export default configureStores;
