import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";
import Landing from "./pages/Landing/Landing";
import Header from "./components/common/Header/Header";
import Footer from "./components/common/Footer/Footer";
import RecentFilings from "./pages/Recent Filings/RecentFilings";
import Loader from "./helpers/RSLoader";
import { ToastContainer } from "react-toastify";
import { setLoading } from "./actions/loading";
import FlashReport from "./components/common/FlashReport/FlashReport";
import CompanyDetails from "./pages/Company Details/CompanyDetails";
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";
import "react-toastify/dist/ReactToastify.css";

const App = (props) => {
  const { trackEvent } = useGoogleAnalytics();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  const { isLoading } = useSelector((state) => state?.loadingActionReducer);

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  useEffect(() => {
    // Track a pageview with a custom page name
    trackEvent(null, null, "pageview", "Home Page");
  }, []);

  return (
    <Router history={history}>
      <>
        <Loader loading={isLoading} />
        <ToastContainer autoClose={2000} />
        <Suspense fallback={<Loader loading={true} />}>
          <section className="main">
            <Header />

            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/recent-filing" component={RecentFilings} />
              <Route
                exact
                path="/company-details/flash-report/:companyName/:companyTicker"
                component={CompanyDetails}
              />
              <Route
                exact
                path="/company-details/flash-report/:companyName/:companyTicker/admin"
                component={CompanyDetails}
              />
              <Route
                exact
                path="/company-details/flash-report/:companyName/:companyTicker/:reportLink"
                component={CompanyDetails}
              />
              <Route
                exact
                path="/company-details/diff-checker/:companyName/:companyTicker"
                component={CompanyDetails}
              />
              <Route
                exact
                path="/company-details/risk-analysis/:companyName/:companyTicker"
                component={CompanyDetails}
              />
              <Route
                exact
                path="/company-details/sentiment-analysis/:companyName/:companyTicker"
                component={CompanyDetails}
              />
            </Switch>

            <Footer />
          </section>
        </Suspense>
      </>
    </Router>
  );
};

export default App;
