import { useState } from "react";
import moment from "moment";
import FilingsService from "../services/filings.service";
import useGoogleAnalytics from "./useGoogleAnalytics";

const useStockData = () => {
  const [resultData, setResultData] = useState();
  const [showFiling, setShowFiling] = useState({});
  const [chartData, setChartData] = useState([]);
  const [showFil, setShowFil] = useState({});
  const { trackEvent } = useGoogleAnalytics();

  let globalStockPrice = [];
  let stockArray = [];
  let array1 = [];
  let graph = [];
  let newArray = [];
  let chartArray = [];

  function handleClick(event) {
    setResultData(event);
    trackEvent("Chart data point click on risk chart", "Click", "event");
  }

  const getStockPrice = async (companyTicker, year, fileId) => {
    let filingQuarters = [];
    let timestampsToDate = [];
    let riskData = [];

    const getFillingsResponse = await FilingsService.getFilings(
      companyTicker,
      year
    );

    riskData = await getFillingsResponse?.data?.data;

    await riskData?.map((item) => {
      return filingQuarters.push({
        quarters: moment(item?.filedata?.date).format("YYYY-M-D"),
        filingType: item?.filedata?.filing_type,
        quarterType: item?.filedata?.quarter,
        colorIndex: item?.filedata?.filing_type,
        color: item?.filedata?.filing_type === "10-K" ? " #FFB700" : "#2B73D0",
        dates: moment(item?.filedata?.date).format(" MMM, D, YYYY"), // LL
        date: item?.filedata?.date?.substr(0, 4),
        cosine_sim: item?.filedata?.cosine_sim.toFixed(3),
        cosine_sim_pre: item?.filedata?.cosine_sim_pre.toFixed(3),
        quarter: item?.filedata?.quarter,
        sentiment_positive: item?.filedata?.sentiment_positive,
        sentiment_strong_modal: item?.filedata?.sentiment_strong_modal,
        sentiment_litigious: item?.filedata?.sentiment_litigious,
        sentiment_negative: item?.filedata?.sentiment_negative,
        sentiment_constraining: item?.filedata?.sentiment_constraining,
        sentiment_uncertainty: item?.filedata?.sentiment_uncertainty,
        sentiment_weak_modal: item?.filedata?.sentiment_weak_modal,
        file_url: item?.filedata?.file_url,
        company: item?.filedata?.company,
        risk_factors: (
          Math.round(item?.filedata?.risk_factors * 100) / 100
        ).toFixed(2),
        stockprice: item?.filedata?.stockprice.toFixed(2),
        ancestor: item?.ancestor,
        preancestor: item?.preancestor,
        qancestor: item?.qancestor,
        median_sentiment_data: item?.median_sentiment_data,
        diffstatus: item?.filedata?.diffstatus,
      });
    });

    const getStockPriceResponse = await FilingsService.getStockPrice(
      companyTicker,
      year === 2 ? "2y" : year === 5 ? "5y" : year === 10 ? "10y" : "5y"
    );

    let timestamps = await getStockPriceResponse?.data?.data?.chart?.result?.[0]
      ?.timestamp;

    let stockPrices = await getStockPriceResponse?.data?.data?.chart
      ?.result?.[0]?.indicators?.quote?.[0]?.close;

    let filingQuarterLength = filingQuarters?.length;
    let stockedPrices;
    let fileCounter = 0;
    let yearsArray = [];

    for (let i = 0; i < timestamps?.length; i++) {
      stockedPrices =
        stockPrices[i] === null || stockPrices[i] === 0
          ? stockPrices[i - 1]
          : stockPrices[i];
      let datee = new Date(timestamps[i] * 1000);

      let years = datee.getFullYear();
      let months = datee.getMonth() + 1;
      let dayy = datee.getDate();

      timestampsToDate.push(years + "-" + months + "-" + dayy);
      yearsArray.push(years);

      if (
        fileCounter < filingQuarterLength &&
        filingQuarters[fileCounter]?.quarters === timestampsToDate[i]
      ) {
        globalStockPrice.push(
          (stockPrices[i] === null || stockPrices[i]) === 0
            ? stockPrices[i - 1]
            : stockPrices[i]
        );

        let stockObj = {
          y:
            (stockPrices[i] === null || stockPrices[i]) === 0
              ? parseFloat(
                  (Math.round(stockPrices[i - 1] * 100) / 100).toFixed(2)
                )
              : parseFloat((Math.round(stockPrices[i] * 100) / 100).toFixed(2)),
          colorIndex: filingQuarters[fileCounter]?.filingType,
          color:
            filingQuarters[fileCounter]?.filingType === "10-K"
              ? " #FFB700"
              : "#2B73D0",
          date: filingQuarters[fileCounter]?.date?.substr(0, 4),
          quarterType: filingQuarters[fileCounter]?.quarterType,
          quarterDate: filingQuarters[fileCounter]?.quarters,
          quarter: filingQuarters[fileCounter]?.quarter,
          cosine_sim: filingQuarters[fileCounter]?.cosine_sim,
          cosine_sim_pre: filingQuarters[fileCounter]?.cosine_sim_pre,
          ancestor: filingQuarters[fileCounter]?.ancestor,
          preancestor: filingQuarters[fileCounter]?.preancestor,
          qancestor: filingQuarters[fileCounter]?.qancestor,
          sentiment_positive: filingQuarters[fileCounter]?.sentiment_positive,
          sentiment_strong_modal:
            filingQuarters[fileCounter]?.sentiment_strong_modal,
          sentiment_litigious: filingQuarters[fileCounter]?.sentiment_litigious,
          sentiment_negative: filingQuarters[fileCounter]?.sentiment_negative,
          sentiment_constraining:
            filingQuarters[fileCounter]?.sentiment_constraining,
          sentiment_uncertainty:
            filingQuarters[fileCounter]?.sentiment_uncertainty,
          sentiment_weak_modal:
            filingQuarters[fileCounter]?.sentiment_weak_modal,
          risk_factors: (
            Math.round(filingQuarters[fileCounter]?.risk_factors * 100) / 100
          ).toFixed(2),
          stockprice:
            (stockPrices[i] === null || stockPrices[i]) === 0
              ? (Math.round(stockPrices[i - 1] * 100) / 100).toFixed(2)
              : (Math.round(stockPrices[i] * 100) / 100).toFixed(2),
          file_url: filingQuarters[fileCounter]?.file_url,
          median_sentiment_data:
            filingQuarters[fileCounter]?.median_sentiment_data,
          diffstatus: filingQuarters[fileCounter]?.diffstatus,
          marker: {
            enabled: filingQuarters[fileCounter]?.quarters ? true : false,
            radius: 5,
          },

          events: {
            click: filingQuarters[fileCounter]?.quarters ? handleClick : "",
          },

          dates: moment(filingQuarters[fileCounter]?.quarters).format(
            "MMM, D, YYYY"
          ),
        };
        stockArray.push(stockObj);

        fileCounter = fileCounter + 1;
      }
    }

    // For the first time when we come from diff checker we get fileId from redux and this id is selected diff checker file
    // then we show data related to this file in table

    if (fileId) {
      const dataFilter = await riskData?.find(
        (data) => data?.filedata?.id === fileId
      );

      let dataToFilter;

      //   if Api does't give the latest file parse then show the last one latest in the result table

      dataFilter
        ? (dataToFilter = dataFilter)
        : (dataToFilter = riskData[riskData.length - 1]);

      const dataToFilterObj = {
        y: parseFloat(
          dataToFilter?.filedata?.risk_factors === null
            ? 0
            : Math.round(dataToFilter?.filedata?.risk_factors * 100) /
                (100).toFixed(2)
        ),
        colorIndex: dataToFilter?.filedata?.filing_type,
        color:
          dataToFilter?.filedata?.filing_type === "10-K"
            ? " #FFB700"
            : "#2B73D0",
        dates: moment(dataToFilter?.filedata?.date).format("MMM, D, YYYY"), // LL
        date: dataToFilter?.filedata?.date?.substr(0, 4),

        cosine_sim: dataToFilter?.filedata?.cosine_sim.toFixed(3),
        cosine_sim_pre: dataToFilter?.filedata?.cosine_sim_pre.toFixed(3),
        quarter: dataToFilter?.filedata?.quarter,
        sentiment_positive: dataToFilter?.filedata?.sentiment_positive,
        sentiment_strong_modal: dataToFilter?.filedata?.sentiment_strong_modal,
        sentiment_litigious: dataToFilter?.filedata?.sentiment_litigious,
        sentiment_negative: dataToFilter?.filedata?.sentiment_negative,
        sentiment_constraining: dataToFilter?.filedata?.sentiment_constraining,
        sentiment_uncertainty: dataToFilter?.filedata?.sentiment_uncertainty,
        sentiment_weak_modal: dataToFilter?.filedata?.sentiment_weak_modal,
        file_url: dataToFilter?.filedata?.file_url,
        company: dataToFilter?.filedata?.company,
        risk_factors: (
          Math.round(dataToFilter?.filedata?.risk_factors * 100) / 100
        ).toFixed(2),
        stockprice: dataToFilter?.filedata?.stockprice.toFixed(2),
        ancestor: dataToFilter?.ancestor,
        preancestor: dataToFilter?.preancestor,
        qancestor: dataToFilter?.qancestor,
        median_sentiment_data: dataToFilter?.median_sentiment_data,
        diffstatus: dataToFilter?.filedata?.diffstatus,
        marker: {
          radius: 5,
        },
      };
      setResultData(dataToFilterObj);
    }

    await riskData?.filter((item, index) => {
      array1?.push({
        y: parseFloat(
          item?.filedata?.risk_factors === null
            ? 0
            : Math.round(item?.filedata?.risk_factors * 100) / (100).toFixed(2)
        ),
        colorIndex: item?.filedata?.filing_type,
        color: item?.filedata?.filing_type === "10-K" ? " #FFB700" : "#2B73D0",
        dates: moment(item?.filedata?.date).format("MMM, D, YYYY"), // LL
        date: item?.filedata?.date?.substr(0, 4),

        cosine_sim: item?.filedata?.cosine_sim.toFixed(3),
        cosine_sim_pre: item?.filedata?.cosine_sim_pre.toFixed(3),
        quarter: item?.filedata?.quarter,
        sentiment_positive: item?.filedata?.sentiment_positive,
        sentiment_strong_modal: item?.filedata?.sentiment_strong_modal,
        sentiment_litigious: item?.filedata?.sentiment_litigious,
        sentiment_negative: item?.filedata?.sentiment_negative,
        sentiment_constraining: item?.filedata?.sentiment_constraining,
        sentiment_uncertainty: item?.filedata?.sentiment_uncertainty,
        sentiment_weak_modal: item?.filedata?.sentiment_weak_modal,
        file_url: item?.filedata?.file_url,
        company: item?.filedata?.company,
        risk_factors: (
          Math.round(item?.filedata?.risk_factors * 100) / 100
        ).toFixed(2),
        stockprice: item?.filedata?.stockprice.toFixed(2),
        ancestor: item?.ancestor,
        preancestor: item?.preancestor,
        qancestor: item?.qancestor,
        median_sentiment_data: item?.median_sentiment_data,
        diffstatus: item?.filedata?.diffstatus,
        marker: {
          radius: 5,
        },
      });

      if (!fileId) {
        setResultData(array1[array1.length - 1]);
      }

      setShowFiling(array1);
      graph.push(item?.filing_type);
    });

    newArray = Array.from(new Set(array1));

    let oldDate = null;
    let count1 = 0;
    let previousDate = newArray[0]?.date;

    newArray?.map((item) => {
      if (previousDate === item?.date) {
        if (count1 === 0) {
          oldDate = previousDate;
        } else {
          oldDate = "";
        }
      } else {
        previousDate = item?.date;
        oldDate = previousDate;
      }
      count1++;
      chartArray?.push([oldDate]);
    });

    setChartData(chartArray);
    setShowFil(stockArray);
  };

  return {
    resultData,
    showFiling,
    chartData,
    showFil,
    getStockPrice,
    handleClick,
  };
};

export default useStockData;
