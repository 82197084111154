import { COMPANY_LIST } from "../actions/types";
const initialState = [];

const apidataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPANY_LIST:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};
export default apidataReducer;
