import { combineReducers } from "@reduxjs/toolkit";
import message from "./message";
import apidata from "./apidata";
import loadingActionReducer from "./loading";
import selectedFileSlice from "../actions/selectedFileSlice";
import diffCheckerSlice from "../actions/diffCheckerSlice";
import filtersTableSlice from "../actions/filtersTableSlice";
import flashReportSlice from "../actions/flashReportSlice";

export default combineReducers({
  message,
  apidata,
  loadingActionReducer,
  selectedFileSlice,
  diffCheckerSlice,
  filtersTableSlice,
  flashReportSlice,
});
