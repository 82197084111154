import React from "react";
import "./SearchBox.scss";

const SearchBox = ({ placeholder, handleChange, value }) => {
  return (
    <div>
      <input
        type="search"
        className="search"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
};

export default SearchBox;
