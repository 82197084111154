import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  addNewComment,
  deleteComment,
  handleActiveComment,
  handleEditComment,
  hasItems,
  hightLightText,
  submitEditComment,
  validationSchema,
} from "./FlashReportUtility";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { sentimentsData } from "../../../helpers/constants";
import { images } from "../../../helpers/images";
import DiffCheckerService from "../../../services/diffchecker.service";
import FlashReportService from "../../../services/flashreport.service";
import {
  dateMMMddyyyy,
  getYear,
  quarterConverter,
} from "../../../helpers/utility-functions";
import RSLoader from "../../../helpers/RSLoader";
import AddSentimentsForm from "./FlashReportComponents/AddSentimentsForm";
import SentimentCommentsSection from "./FlashReportComponents/SentimentCommentsSection";
import "./FlashReport.scss";

const FlashReport = ({ companyTicker, setFlashReportLink }) => {
  const { reportLink, companyName } = useParams();
  const [getComments, setGetComments] = useState();
  const [maintainPreviousComments, setMaintainPreviousComments] = useState();
  const [characterCount, setCharacterCount] = useState(0);
  const [renderDocument, setRenderDocument] = useState("");
  const [addFlashReportForm, setAddFlashReportForm] = useState(false);
  const [negativeFlashReportForm, setNegativeFlashReportForm] = useState({
    negativeComment: false,
    negativeCommentId: null,
  });
  const [positiveFlashReportForm, setPositiveFlashReportForm] = useState({
    positiveComment: false,
    positiveCommentId: null,
  });
  const [neutralFlashReportForm, setNeutralFlashReportForm] = useState({
    neutralComment: false,
    neutralCommentId: null,
  });
  const parentElementRef = useRef(null);
  const currentTooltipRef = useRef(null);
  const currentSelectedTextRef = useRef(null);
  const location = useLocation();
  const [filesList, setFilesList] = useState([]);
  const [firstFileDetails, setFirstFileDetails] = useState();
  const [secondFileDetails, setSecondFileDetails] = useState();
  const [quarterValue, setQuarterValue] = useState();
  const [editComment, setEditComment] = useState();
  const [activeCommentIndex, setActiveCommentIndex] = useState(null);
  const [loader, setLoader] = useState();
  const [dataSpanId, setDataSpanID] = useState(null);
  const [filesCta, setFilesCta] = useState();
  const history = useHistory();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#1E2449" : "#1E2449",
      backgroundColor: state.isSelected ? "transparent" : "transparent",
      fontWeight: state.isSelected ? "bold" : "400",
      cursor: state.isDisabled ? "not-allowed !important" : "pointer",
    }),
  };

  useEffect(() => {
    if (reportLink) {
      getFlashReportThroughId(reportLink);
      return;
    }
    if (companyTicker) {
      getFilesList();
    }
  }, [companyTicker]);

  // When user comes directly with ID
  const getFlashReportThroughId = async (id) => {
    const result = {
      flash_id: id,
    };

    const res = await FlashReportService.getFlashReport(result);
    if (res?.data?.message === "Flash file has been retrieved successfully.") {
      await convertHtmlToReact(res?.data?.data?.file_url);
      setGetComments(res?.data?.data);
      setFlashReportLink(res?.data?.data?.flash_id);
      setMaintainPreviousComments(res?.data?.data);
    } else {
      setGetComments(res?.data?.data);
      setMaintainPreviousComments(res?.data?.data);
    }
  };

  const getFilesList = async () => {
    const res = await DiffCheckerService.getQuarters(companyTicker, "");
    setFilesList(res);
    getRecentFileIDs(res);
  };
  const getRecentFileIDs = async (data) => {
    let fileIndex = 0;
    let secondFile;
    let quarter = data[0]?.quarter;
    let quarterValue = quarterConverter(quarter);

    if (quarterValue === "10-K") {
      // Find the index of the next 10k file
      secondFile = data?.find(
        (file, index) => index > fileIndex && file.quarter === "10K"
      );
    } else {
      // Find the index of the next file with the same quarter
      secondFile = data?.find(
        (file, index) =>
          index > fileIndex && quarterConverter(file?.quarter) === quarterValue
      );
    }

    const firstFile_id = data[0]?.id;
    const secondFile_id = secondFile?.id;
    setQuarterValue(quarterValue);
    setFirstFileDetails(data[0]);
    setSecondFileDetails(secondFile);

    const result = {
      fileFirst_id: firstFile_id,
      fileSecond_id: secondFile_id,
      filing_type: quarterValue,
      ticker: companyTicker,
    };

    const res = await FlashReportService.getFlashReport(result);
    if (res?.data?.message === "Flash file has been retrieved successfully.") {
      await convertHtmlToReact(res?.data?.data?.file_url);
      setGetComments(res?.data?.data);
      setFlashReportLink(res?.data?.data?.flash_id);
      setMaintainPreviousComments(res?.data?.data);
    } else {
      // if (res?.data?.data === null) {
      //   history.push(
      //     `/company-details/diff-checker/${companyName}/${companyTicker}`
      //   );
      // }
      setRenderDocument("");
      setGetComments(res?.data?.data);
      setMaintainPreviousComments(res?.data?.data);
    }
  };

  const handleChange = (setFieldValue, e) => {
    if (e.target) {
      // Handle the input field change
      const { name, value } = e.target;
      setFieldValue(name, value);
      setCharacterCount(value.length);
    } else {
      // Handle the Select change
      setFieldValue("sentiments", e);
    }
  };

  // handle add new comment
  const handleSubmitAddComment = (values, { resetForm, setFieldValue }) => {
    addNewComment(
      values,
      { resetForm, setFieldValue },
      currentSelectedTextRef,
      sentimentsData,
      setCharacterCount,
      setAddFlashReportForm,
      maintainPreviousComments,
      companyTicker,
      firstFileDetails,
      secondFileDetails,
      quarterValue,
      getFilesList,
      dataSpanId
    );
  };

  const handleSubmitEditComment = (values, { resetForm, setFieldValue }) => {
    submitEditComment(
      values,
      { resetForm, setFieldValue },
      editComment,
      sentimentsData,
      setCharacterCount,
      getComments,
      getFilesList,
      setEditComment,
      firstFileDetails,
      secondFileDetails,
      quarterValue,
      companyTicker,
      setNegativeFlashReportForm,
      setPositiveFlashReportForm,
      setNeutralFlashReportForm
    );
  };

  const handleRenderDocument = async () => {
    // Set the default selected values when the component mounts
    let fileIndex = 0;
    let secondFile;
    let quarter = filesList[0]?.quarter;
    let quarterValue = quarterConverter(quarter);

    if (quarterValue === "10-K") {
      // Find the index of the next 10k file
      secondFile = filesList?.find(
        (file, index) => index > fileIndex && file.quarter === "10K"
      );
    } else {
      // Find the index of the next file with the same quarter
      secondFile = filesList?.find(
        (file, index) =>
          index > fileIndex && quarterConverter(file?.quarter) === quarterValue
      );
    }

    const firstFile_id = filesList[0]?.id;
    const secondFile_id = secondFile?.id;
    setLoader(true);
    const res = await DiffCheckerService.compareFiles(
      companyTicker,
      quarterValue,
      firstFile_id,
      secondFile_id
    );
    setFilesCta(res?.files);

    await convertHtmlToReact(res?.data);
  };

  const convertHtmlToReact = async (url) => {
    try {
      const response = await axios.get(url);
      if (response?.data) {
        setRenderDocument(response?.data);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      return await error;
    }
  };
  // function to handle highlight the text
  const handleMouseUp = () => {
    // utility function highLightText and pass refrences and states to this function .
    hightLightText(
      parentElementRef,
      currentTooltipRef,
      currentSelectedTextRef,
      setAddFlashReportForm,
      setNegativeFlashReportForm,
      setPositiveFlashReportForm,
      setNeutralFlashReportForm,
      setActiveCommentIndex,
      setDataSpanID
    );
  };
  // function handles when we click one of the comments it redirects to the selected text that is relevent to the comment .
  const handleAnchorClick = (event, id) => {
    handleActiveComment(event, id, setActiveCommentIndex);
  };
  //function to handle delete comment
  const handleDeleteComments = (id, index, commentType, spansId) => {
    deleteComment(
      id,
      index,
      commentType,
      spansId,
      getComments,
      firstFileDetails,
      secondFileDetails,
      quarterValue,
      companyTicker,
      getFilesList,
      setNegativeFlashReportForm,
      setPositiveFlashReportForm,
      setNeutralFlashReportForm
    );
  };
  // function to handle edit comment
  const handleEditComments = (id, commentType) => {
    handleEditComment(
      id,
      commentType,
      getComments,
      setEditComment,
      setAddFlashReportForm,
      setNegativeFlashReportForm,
      setPositiveFlashReportForm,
      setNeutralFlashReportForm
    );
  };
  // clear form
  const handleClear = (resetForm) => {
    const spanToRemove = document.querySelectorAll(
      `[data-span-id="${dataSpanId}"]`
    );
    // Loop through each span and remove it
    spanToRemove.forEach((span) => {
      // Get the parent of the span
      let parentElement = span.parentNode;
      // Move the span's children (content) to the parent before removing the span
      while (span.firstChild) {
        parentElement.insertBefore(span.firstChild, span);
      }
      // Remove the empty span
      parentElement.removeChild(span);
    });
    resetForm();
    setAddFlashReportForm(false);
  };
  // clear form
  const handleClearEditForm = (resetForm, formType) => {
    if (formType === "negative") {
      resetForm();
      setNegativeFlashReportForm({
        negativeComment: false,
        negativeCommentId: null,
      });
    }
    if (formType === "positive") {
      resetForm();
      setPositiveFlashReportForm({
        positiveComment: false,
        positiveCommentId: null,
      });
    }
    if (formType === "neutral") {
      resetForm();
      setNeutralFlashReportForm({
        neutralComment: false,
        neutralCommentId: null,
      });
    }
  };

  const handleViewDocument = (fileUrl, fileId) => {
    window.open(fileUrl, `documentTab_${fileId}`, "noopener");
  };

  return (
    <section className="flash-report-main">
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-md-2">
          <div className="comments-section">
            <div>
              {getComments !== null && (
                <h3 className="outline-text">Highlights</h3>
              )}
              {renderDocument && getComments === null && (
                <div className="mb-16 no-comments-container">
                  <div className="show-comments">
                    <img
                      src={images.NoFLashReport}
                      className="mr-20 img-fluid"
                      alt="sentiments"
                    />

                    <p className="comment-title">
                      There’s no Flash Comments yet.
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="vertical-outline">
              {addFlashReportForm && (
                <AddSentimentsForm
                  sentimentsData={sentimentsData}
                  handleChange={handleChange}
                  characterCount={characterCount}
                  handleClear={handleClear}
                  handleSubmitAddComment={handleSubmitAddComment}
                />
              )}
              {getComments === null && !renderDocument && (
                <div>
                  <div className="mb-16 no-comments-container">
                    <div className="show-comments">
                      <img
                        src={images.NoFLashReport}
                        className="mr-20 img-fluid"
                        alt="sentiments"
                      />
                      {location.pathname.includes("admin") ? (
                        <p className="comment-title">
                          There’s no Flash Report yet. Click the button below to
                          add one.
                        </p>
                      ) : (
                        <p className="comment-title">
                          There’s no Flash Report yet.
                        </p>
                      )}
                    </div>
                  </div>
                  {location.pathname.includes("admin") && (
                    <button
                      onClick={handleRenderDocument}
                      data-testid="clearbtn"
                      className="btn btn-primary  mb-3 mb-sm-0 btn-outline"
                    >
                      Start
                    </button>
                  )}
                </div>
              )}
              {getComments !== null &&
                getComments !== "none" &&
                hasItems(getComments?.flash_sentiments?.negative) && (
                  <SentimentCommentsSection
                    sentimentType="negative"
                    flashReportForm={negativeFlashReportForm}
                    activeCommentIndex={activeCommentIndex}
                    handleAnchorClick={handleAnchorClick}
                    handleEditComments={handleEditComments}
                    handleDeleteComments={handleDeleteComments}
                    sentimentsData={sentimentsData}
                    handleChange={handleChange}
                    images={images}
                    editComment={editComment}
                    handleSubmitEditComment={handleSubmitEditComment}
                    validationSchema={validationSchema}
                    characterCount={characterCount}
                    handleClearEditForm={handleClearEditForm}
                    getComments={getComments}
                    location={location.pathname.includes("admin")}
                  />
                )}
              {getComments !== null &&
                getComments !== "none" &&
                hasItems(getComments?.flash_sentiments?.positive) && (
                  <SentimentCommentsSection
                    sentimentType="positive"
                    flashReportForm={positiveFlashReportForm}
                    activeCommentIndex={activeCommentIndex}
                    handleAnchorClick={handleAnchorClick}
                    handleEditComments={handleEditComments}
                    handleDeleteComments={handleDeleteComments}
                    sentimentsData={sentimentsData}
                    handleChange={handleChange}
                    images={images}
                    editComment={editComment}
                    handleSubmitEditComment={handleSubmitEditComment}
                    validationSchema={validationSchema}
                    characterCount={characterCount}
                    handleClearEditForm={handleClearEditForm}
                    getComments={getComments}
                    location={location.pathname.includes("admin")}
                  />
                )}
              {getComments !== null &&
                getComments !== "none" &&
                hasItems(getComments?.flash_sentiments?.neutral) && (
                  <SentimentCommentsSection
                    sentimentType="neutral"
                    flashReportForm={neutralFlashReportForm}
                    activeCommentIndex={activeCommentIndex}
                    handleAnchorClick={handleAnchorClick}
                    handleEditComments={handleEditComments}
                    handleDeleteComments={handleDeleteComments}
                    sentimentsData={sentimentsData}
                    handleChange={handleChange}
                    images={images}
                    editComment={editComment}
                    handleSubmitEditComment={handleSubmitEditComment}
                    validationSchema={validationSchema}
                    characterCount={characterCount}
                    handleClearEditForm={handleClearEditForm}
                    getComments={getComments}
                    location={location.pathname.includes("admin")}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-9 col-md-10">
          {renderDocument && (
            <div className="comparing-flash-report d-flex justify-content-between">
              <div className="d-flex  align-items-center">
                <span className="compare-text">Comparing</span>

                <div className="file-details">
                  <div
                    className="first-file-detail"
                    onClick={() =>
                      handleViewDocument(
                        filesCta?.first_file_url || getComments?.first_file_url,
                        "firstFile"
                      )
                    }
                  >
                    {reportLink ? (
                      <div className="filing-main">
                        <span className="filing-type">
                          {getComments?.filing_type
                            ? getComments?.filing_type?.includes("10-Q")
                              ? `10Q ${getYear(getComments?.first_file_date)}`
                              : `10K ${getYear(getComments?.first_file_date)}`
                            : "-"}
                        </span>
                        <span className="filing-date">
                          {getComments?.first_file_date &&
                            dateMMMddyyyy(getComments?.first_file_date)}
                        </span>
                      </div>
                    ) : (
                      <div className="filing-main">
                        <span className="filing-type">
                          {firstFileDetails?.quarter.includes("Quarter")
                            ? `10Q ${firstFileDetails?.fileyear}`
                            : firstFileDetails?.fileyear
                            ? `10K ${firstFileDetails?.fileyear}`
                            : `10K - `}
                        </span>
                        <span className="filing-date">
                          {firstFileDetails?.date &&
                            dateMMMddyyyy(firstFileDetails?.date)}
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className="second-file-detail"
                    onClick={() =>
                      handleViewDocument(
                        filesCta?.second_file_url ||
                          getComments?.second_file_url,
                        "secondFile"
                      )
                    }
                  >
                    {reportLink ? (
                      <div className="filing-main">
                        <span className="filing-type">
                          {getComments?.filing_type
                            ? getComments?.filing_type?.includes("10-Q")
                              ? `10Q ${getYear(getComments?.second_file_date)}`
                              : `10K ${getYear(getComments?.second_file_date)}`
                            : "-"}
                        </span>
                        <span className="filing-date">
                          {getComments?.second_file_date &&
                            dateMMMddyyyy(getComments?.second_file_date)}
                        </span>
                      </div>
                    ) : (
                      <div className="filing-main">
                        <span className="filing-type">
                          {secondFileDetails?.quarter.includes("Quarter")
                            ? `10Q ${secondFileDetails?.fileyear}`
                            : secondFileDetails?.fileyear
                            ? `10K ${secondFileDetails?.fileyear}`
                            : `10K - `}
                        </span>
                        <span className="filing-date">
                          {secondFileDetails?.date &&
                            dateMMMddyyyy(secondFileDetails?.date)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <ul className="inline-ul legend-diff-ul">
                <li>
                  <div className="d-flex align-items-center">
                    <div className="box-legend box-green"></div>
                    <div className="p4">
                      <p>Added</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <div className="box-legend box-pink"></div>
                    <div className="p4">
                      <p>Removed</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <div className="box-legend box-lightgrey"></div>
                    <div className="p4">
                      <p>Highlighted</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}

          <div className="flash-report-document">
            {renderDocument ? (
              <div
                ref={(element) => {
                  parentElementRef.current = element;
                }}
                id="flash-report-content-container"
                className="flash-report-content"
                dangerouslySetInnerHTML={{ __html: renderDocument }}
                onMouseUp={
                  location.pathname.includes("admin")
                    ? handleMouseUp
                    : undefined
                }
              />
            ) : (
              <>
                {loader && <RSLoader loading={loader} showLoaderText={true} />}
                <div className="no-flash-report">
                  <img src={images.Frame} className="img-fluid" alt="frame" />
                  <h3>There’s no Flash Report yet. </h3>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlashReport;
