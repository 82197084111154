import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Accordion, Form } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactSelect from "../../components/ui/form-fields/ReactSelect";
import Table from "../../components/ui/table/Table";
import SearchBox from "../../components/ui/search-box/SearchBox";
import { filings, sentiments } from "../../helpers/constants";
import {
  makeLabelValuePair,
  uniqueIndustries,
  uniqueSectors,
  uniqueTable,
} from "../../helpers/utility-functions";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import TextOverlayToolTip from "../../components/common/TextOverLayToolTip/TextOverLayToolTip";
import { CompanyColumns } from "../../helpers/Columns";
import CompaniesService from "../../services/companies.service";
import { setFilterValues } from "../../actions/filtersTableSlice";
import { companyList } from "../../actions/allCompaniesData";
import FilterOptions from "./FilterOptions";
import "./RecentFilings.scss";

const RecentFilings = () => {
  const {
    filingFilter,
    highlightsFilter,
    industryFilter,
    sectorFilter,
    companyFilter,
  } = useSelector((state) => state.filtersTableSlice.filters);

  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(
    companyFilter || []
  );
  const [concatenatedData, setConcatenatedData] = useState([]);
  const [concatenatedFilterData, setConcatenatedFilterData] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState(sectorFilter || []);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState(
    industryFilter || []
  );
  const [filing, setFiling] = useState(filings);
  const [selectedFiling, setSelectedFiling] = useState(filingFilter || []);
  const [selectedSentiments, setSelectedSentiments] = useState(
    highlightsFilter || []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [searchIndustry, setSearchIndustry] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [dataToShowState, setDataToShowState] = useState([]);
  const pageSize = 20;
  const history = useHistory();

  const { trackEvent } = useGoogleAnalytics();

  useEffect(() => {
    // Track a pageview with a custom page name
    trackEvent(null, null, "pageview", "Recent Filings");
  }, []);

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const companiesList = await CompaniesService.getCompaniesList();
    setTableData(companiesList);
    // save companies list in redux thats why use dispatch
    dispatch(companyList(companiesList));
    const res = makeLabelValuePair(companiesList);
    const uniqueSector = uniqueSectors(companiesList);
    const uniqueIndustry = uniqueIndustries(companiesList);
    setCompanies(res);
    setSectors(uniqueSector);
    setIndustries(uniqueIndustry);
  };

  // when component unmount we want to store filters in redux store
  useEffect(() => {
    return () => {
      dispatch(
        setFilterValues({
          filingFilter: selectedFiling,
          highlightsFilter: selectedSentiments,
          sectorFilter: selectedSectors,
          industryFilter: selectedIndustries,
          companyFilter: selectedCompanies,
        })
      );
    };
  }, [
    selectedFiling,
    selectedSentiments,
    selectedSectors,
    selectedIndustries,
    selectedCompanies,
  ]);

  // initial state of react multi select
  const initialValuesCheckbox = {
    companyCheckbox: selectedCompanies || "",
  };

  // handle change of react multi select
  const handleChanges = (name, value, setFieldValue) => {
    setFieldValue(name, value);
    setSelectedCompanies(value);
    setConcatenatedFilterData([]);
    setCurrentPage(1);
    setConcatenatedData([]);
  };

  // this is for when we show paginated data in case of filteration so loading false when datalength and concatenatedFilterData equals
  useEffect(() => {
    if (dataToShowState?.length > 0) {
      if (dataToShowState?.length === concatenatedFilterData?.length) {
        setHasMore(false);
      }
    }
  }, [concatenatedFilterData, dataToShowState?.length]);

  const paginatedData = useMemo(() => {
    if (
      selectedIndustries.length > 0 ||
      selectedSectors.length > 0 ||
      selectedCompanies.length > 0 ||
      selectedFiling.length > 0 ||
      selectedSentiments.length > 0
    ) {
      let dataToShow = tableData?.filter((item) => {
        // Check if item matches the selected industries and sectors
        // this case is only for when atleast one sector is selected
        // if industry doesn't belong to any selected sectors than don't consider those industries for filtering

        let industriesToConsider = [];
        let industryMatch;
        if (selectedSectors.length > 0) {
          let filterIndustries = tableData?.filter((item) =>
            selectedSectors.includes(item.sector)
          );
          let filterUniqueIndustries = uniqueIndustries(filterIndustries);

          let filterSelectedIndustries = filterUniqueIndustries?.filter(
            (item) => selectedIndustries.includes(item.industry)
          );
          industriesToConsider = filterSelectedIndustries?.map(
            (item) => item.industry
          );
          // first get only industries that we want to consider for filtering
          industryMatch = industriesToConsider.includes(item.industry);
        } else {
          // seector is not selected then consider all selected industries
          industryMatch = selectedIndustries.includes(item.industry);
        }
        const sectorMatch = selectedSectors.includes(item.sector);
        const companyMatch = selectedCompanies?.some(
          (company) => company.label === item.name
        );
        const filingMatch = selectedFiling.includes(
          item.quarter === "Quarter-1" ||
            item.quarter === "Quarter-2" ||
            item.quarter === "Quarter-3"
            ? "10Q"
            : "10K"
        );

        const sentimentsMatch = selectedSentiments.includes(
          item.flash_sentiments
        );

        // Return true if all filters match (if selected)
        // or if no filters are selected
        return (
          (selectedSectors.length > 0
            ? industriesToConsider.length === 0 || industryMatch
            : selectedIndustries.length === 0 || industryMatch) &&
          (selectedSectors.length === 0 || sectorMatch) &&
          (selectedCompanies.length === 0 || companyMatch) &&
          (selectedFiling.length === 0 || filingMatch) &&
          (selectedSentiments.length === 0 || sentimentsMatch)
        );
      });

      setDataToShowState(dataToShow);
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      setHasMore(true);

      // if no record found, loading state false and show message no record found
      if (dataToShow?.length === 0) {
        setHasMore(false);
      }

      const slicingData = dataToShow?.slice(startIndex, endIndex);
      const newData = concatenatedFilterData.concat(slicingData);
      const uniqueDataTable = uniqueTable(newData);
      setConcatenatedFilterData(uniqueDataTable);

      return uniqueDataTable;
    } else {
      // when no filter is selected than this else runs
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      setHasMore(true);
      const slicingData = tableData?.slice(startIndex, endIndex);

      if (tableData?.length === concatenatedData?.length) {
        setHasMore(false);
      }
      if (slicingData) {
        // Concatenate the new data with the previous data
        const newData = concatenatedData.concat(slicingData);
        const uniqueDataTable = uniqueTable(newData);
        setConcatenatedData(uniqueDataTable);
        return uniqueDataTable;
      }
    }
  }, [
    currentPage,
    selectedSectors,
    selectedIndustries,
    selectedCompanies,
    selectedFiling,
    selectedSentiments,
    tableData,
  ]);

  const fetchMoreData = () => {
    // Fetch more data or load the next page here and setTimeout is for show loading for 1 sec

    setTimeout(() => {
      setCurrentPage((prevPage) => prevPage + 1);
    }, 1500);
  };

  //   filter companies in the basis of industries
  useEffect(() => {
    if (selectedIndustries.length > 0) {
      let filterCompanies = tableData?.filter((item) =>
        selectedIndustries.includes(item.industry)
      );
      const res = makeLabelValuePair(filterCompanies);
      setCompanies(res);
    } else {
      const res = makeLabelValuePair(tableData);
      setCompanies(res);
    }
  }, [tableData, selectedIndustries]);

  //   handle industry change
  const handleIndustryChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      //after setting this, it will filter companies
      setSelectedIndustries((prevSelected) => [...prevSelected, value]);
      setConcatenatedFilterData([]);
      setConcatenatedData([]);
      setCurrentPage(1);
    } else {
      setSelectedIndustries((prevSelected) =>
        prevSelected.filter((industry) => industry !== value)
      );
      setConcatenatedFilterData([]);
      setCurrentPage(1);
      setConcatenatedData([]);
    }
  };

  //   filter industries in the basis of sector
  useEffect(() => {
    if (selectedSectors.length > 0) {
      let filterIndustry = tableData?.filter((item) =>
        selectedSectors.includes(item.sector)
      );
      const filterUniqueIndustries = uniqueIndustries(filterIndustry);
      setIndustries(filterUniqueIndustries);
      const res = makeLabelValuePair(filterIndustry);
      setCompanies(res);
    } else {
      const allUniqueIndustries = uniqueIndustries(tableData);
      setIndustries(allUniqueIndustries);
      const res = makeLabelValuePair(tableData);
      setCompanies(res);
    }
  }, [selectedSectors, tableData]);

  //   handle sector change
  const handleSectorChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedSectors((prevSelected) => [...prevSelected, value]);
      setConcatenatedFilterData([]);
      setConcatenatedData([]);
      setCurrentPage(1);
    } else {
      setSelectedSectors((prevSelected) =>
        prevSelected.filter((sector) => sector !== value)
      );
      setConcatenatedFilterData([]);
      setCurrentPage(1);
      setConcatenatedData([]);
    }
  };

  //   handle filing change
  const handleFilingChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedFiling((prevSelected) => [...prevSelected, value]);
      setConcatenatedFilterData([]);
      setCurrentPage(1);
      setConcatenatedData([]);
    } else {
      setSelectedFiling((prevSelected) =>
        prevSelected.filter((filing) => filing !== value)
      );
      setConcatenatedFilterData([]);
      setCurrentPage(1);
      setConcatenatedData([]);
    }
  };

  //   handle filing change
  const handleHighlightsChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedSentiments((prevSelected) => [...prevSelected, value]);
      setConcatenatedFilterData([]);
      setCurrentPage(1);
      setConcatenatedData([]);
    } else {
      setSelectedSentiments((prevSelected) =>
        prevSelected.filter((filing) => filing !== value)
      );
      setConcatenatedFilterData([]);
      setCurrentPage(1);
      setConcatenatedData([]);
    }
  };

  //   this onchange is for when we search industry
  const handleSearchIndustryChange = (e) => {
    setSearchIndustry(e.target.value);
  };

  //   this function triggers when we search industries
  const filteredIndustries = industries?.filter((industry) =>
    industry?.industry?.toLowerCase().includes(searchIndustry?.toLowerCase())
  );

  //   clear selected industries
  const handleClearIndustrySelection = () => {
    setSelectedIndustries([]);
    setConcatenatedFilterData([]);
    setCurrentPage(1);
    setConcatenatedData([]);
  };

  //   clear selected sectors
  const handleClearSectorSelection = () => {
    setSelectedSectors([]);
    setConcatenatedFilterData([]);
    setCurrentPage(1);
    setConcatenatedData([]);
  };

  //   clear selected filing
  const handleClearFilingSelection = () => {
    setSelectedFiling([]);
    setConcatenatedFilterData([]);
    setCurrentPage(1);
    setConcatenatedData([]);
  };

  //   clear selected highlights
  const handleClearHighlightSelection = () => {
    setSelectedSentiments([]);
    setConcatenatedFilterData([]);
    setCurrentPage(1);
    setConcatenatedData([]);
  };

  const cols = useMemo(() => CompanyColumns, []);

  const handleRowClick = async (row) => {
    history.push(
      `/company-details/flash-report/${row?.original?.name}/${row?.original?.ticker}`
    );
  };

  return (
    <section className="recentfilling-main">
      <div className="row">
        <div className="col-xl-2 col-lg-3">
          <h2>Recent Filings</h2>
          <div className="vertical-sidebar">
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <FilterOptions
                name="sentiment"
                title="Highlights"
                eventKey="0"
                selectedValues={selectedSentiments}
                handleClearSelection={handleClearHighlightSelection}
                options={sentiments}
                handleFilterChange={handleHighlightsChange}
                showSummary={selectedSentiments.length > 0}
                labelProperty="label" // Specify the dynamic label property
                valueProperty="value" // Specify the dynamic value property
                checkedProperty="value" // Specify the dynamic checked property
                addClassName={true}
              />
              <FilterOptions
                name="filing"
                title="Filing"
                eventKey="1"
                selectedValues={selectedFiling}
                handleClearSelection={handleClearFilingSelection}
                options={filing}
                handleFilterChange={handleFilingChange}
                showSummary={selectedFiling.length > 0}
                labelProperty="label" // Specify the dynamic label property
                valueProperty="value" // Specify the dynamic value property
                checkedProperty="value" // Specify the dynamic checked property
              />
              <FilterOptions
                name="sectors"
                title="Sector"
                eventKey="2"
                selectedValues={selectedSectors}
                handleClearSelection={handleClearSectorSelection}
                options={sectors}
                handleFilterChange={handleSectorChange}
                showSummary={selectedSectors.length > 0}
                labelProperty="sector" // Specify the dynamic label property
                valueProperty="sector" // Specify the dynamic value property
                checkedProperty="sector" // Specify the dynamic checked property
              />
              <FilterOptions
                name="industries"
                title="Industry"
                eventKey="3"
                selectedValues={selectedIndustries}
                handleClearSelection={handleClearIndustrySelection}
                options={filteredIndustries}
                handleFilterChange={handleIndustryChange}
                showSummary={selectedIndustries.length > 0}
                labelProperty="industry" // Specify the dynamic label property
                valueProperty="industry" // Specify the dynamic value property
                checkedProperty="industry" // Specify the dynamic checked property
                labelComponent={(label) => (
                  <TextOverlayToolTip
                    textString={label}
                    charactersLength={20}
                  />
                )}
                customComponent={
                  <SearchBox
                    placeholder="Search industry..."
                    handleChange={handleSearchIndustryChange}
                    value={searchIndustry}
                  />
                }
              />
            </Accordion>
          </div>
        </div>
        <div className="col-xl-10 col-lg-9">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Formik enableReinitialize initialValues={initialValuesCheckbox}>
                {({ errors, values, setFieldValue }) => (
                  <Form noValidate>
                    <div className="form-group">
                      <div className="form-controls">
                        <ReactSelect
                          name="companyCheckbox"
                          options={companies}
                          placeholderText="Search by company name or ticker"
                          isMulti
                          errors={errors}
                          values={values}
                          handleChanges={handleChanges}
                          setFieldValue={setFieldValue}
                          getOptionLabel={(option) => `${option.label}`}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="col-md-12">
              <div className="tableresponsive table-wrapper mt-10 table-wrapper-cl">
                {tableData && (
                  <div
                    id="scrollableDiv"
                    style={{
                      overflow: "auto",
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={paginatedData?.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      height="82vh"
                      loader={
                        <div className="loader-parent">
                          <div className="scroll-loader">
                            <div className="dot-flashing"></div>
                            <span className="loading-more">Load More</span>
                          </div>
                        </div>
                      }
                      endMessage={
                        paginatedData.length == 0 && (
                          <h3 className="no-more-records">No records found</h3>
                        )
                      }
                    >
                      <Table
                        columns={cols}
                        data={paginatedData}
                        rowProps={(row) => ({
                          onClick: () => {
                            handleRowClick(row);
                          },
                          style: {
                            cursor: "pointer",
                          },
                        })}
                      />
                    </InfiniteScroll>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecentFilings;
