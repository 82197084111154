import React from "react";
import { images } from "../../../helpers/images";
import { showFileOrMedian } from "../../../helpers/utility-functions";

const SentimentAnalysisTable = ({ sentimentsData, avgOrMedian, file }) => {
  const boxBlueYellow = (data, box, type) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className={`box-table ${box}`}></div>
        <h3>{showFileOrMedian(data, type)}</h3>
      </div>
    );
  };
  return (
    <div className="col-12">
      <div className="table-sa-height">
        <table className="table table-sa mt-16">
          <tbody>
            {sentimentsData?.map((data, index) => {
              const { wordType, diff } = data;
              return (
                <>
                  <tr key={index}>
                    <td>
                      <h3>{wordType}:</h3>
                    </td>

                    <td>{boxBlueYellow(data, "box-blue", file)}</td>

                    <td>{boxBlueYellow(data, "box-yellow", avgOrMedian)}</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <h3>
                          {diff === null
                            ? "--"
                            : (Math.round(diff * 100) / 100).toFixed(1) + "%"}
                        </h3>
                        {(wordType === "Positive" ||
                          wordType === "Strong Modal") && (
                          <div
                            className={`${diff < 0 ? "red-down" : "green-up"}`}
                          >
                            {diff < 0 ? (
                              <img
                                src={images.redIcon}
                                className="img-fluid"
                                alt="arrow"
                              />
                            ) : (
                              <img
                                src={images.greenIcon}
                                className="img-fluid"
                                alt="arrow"
                              />
                            )}
                          </div>
                        )}
                        {(wordType === "Negative" ||
                          wordType === "Litigious" ||
                          wordType === "Constraining" ||
                          wordType === "Uncertainty" ||
                          wordType === "Weak Modal") && (
                          <div
                            className={`${diff > 0 ? "red-up" : "green-down"}`}
                          >
                            {diff > 0 ? (
                              <img
                                src={images.redIcon}
                                className="img-fluid"
                                alt="arrow"
                              />
                            ) : (
                              <img
                                src={images.greenIcon}
                                className="img-fluid"
                                alt="arrow"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr className="tr-empty"></tr>
                </>
              );
            })}

            {sentimentsData?.length === 0 && (
              <tr>
                <td colSpan="5">No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SentimentAnalysisTable;
