import * as Yup from "yup";
import { images } from "../../../helpers/images";
import FlashReportService from "../../../services/flashreport.service";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .max(100, "Title must be at most 100 characters"),
});

// Function to get the position of an element
function getPosition(element) {
  let rect = element.getBoundingClientRect();
  return {
    top: rect.top + window.scrollY + 20,
    left: rect.left + window.scrollX,
  };
}

/* checks whether an (empty) text node is ignored by the browser or rendered */
function isTextNodeUnparsedWhitespace(node) {
  if (!node || node.nodeType !== Node.TEXT_NODE) {
    return false;
  }

  // Check if the text node is empty or contains only whitespace characters
  if (!/[^\t\n\r ]/.test(node.textContent.trim())) {
    return true;
  }

  const range = document.createRange();
  range.selectNodeContents(node);
  const rects = range.getClientRects();

  // Check if the text node is rendered
  if (rects.length > 0 && rects[0].width > 0) {
    return false;
  }

  return true;
}

/* wrapper function to embed selected text nodes in span */
const hightLightText = (
  parentElementRef,
  currentTooltipRef,
  currentSelectedTextRef,
  setAddFlashReportForm,
  setNegativeFlashReportForm,
  setPositiveFlashReportForm,
  setNeutralFlashReportForm,
  setActiveCommentIndex,
  setDataSpanID
) => {
  const parentElement = parentElementRef.current;
  const selection = window.getSelection();
  if (selection && !selection.isCollapsed) {
    let spanPosition;
    let counter = 0; // Counter variable to track text nodes
    const uniqueDataSpanId = Date.now();

    getSelectedTextNodes().forEach((selection) => {
      selection.forEach((textNode) => {
        // Check if the textNode is not empty
        if (textNode.textContent.trim() !== "") {
          let newSpan = document.createElement("span");

          // Set inline CSS property
          newSpan.style.background = "rgba(192, 192, 192, 0.7)";

          newSpan.setAttribute("class", "custom-class");
          // set same attribute data-span-id for deletion
          const spanId = uniqueDataSpanId;
          newSpan.setAttribute("data-span-id", spanId);
          setDataSpanID(spanId);
          // Set id attribute only for the first non-empty textNode
          if (counter === 0) {
            currentSelectedTextRef.current = newSpan;
          }
          textNode.before(newSpan);
          newSpan.appendChild(textNode);
          spanPosition = getPosition(newSpan);

          // Increment the counter
          counter++;
        }
      });
    });
    // Create and display the tooltip on the parent element
    createTooltip(
      parentElement,
      spanPosition,
      currentTooltipRef,
      currentSelectedTextRef,
      setAddFlashReportForm,
      setNegativeFlashReportForm,
      setPositiveFlashReportForm,
      setNeutralFlashReportForm,
      setActiveCommentIndex
    );
  }
};

/* splits partially selected text nodes in parts, returns all selected text nodes as a two-dimensional array */
const getSelectedTextNodes = () => {
  // Array to store the selected text nodes
  let returnArray = new Array();
  // Get the current text selection
  let selection = window.getSelection();
  // Loop through each range in the selection
  for (
    let rangeNumber = selection.rangeCount - 1;
    rangeNumber >= 0;
    rangeNumber--
  ) {
    // Array to store text nodes within the current range
    let rangeNodes = new Array();
    // Get the current range
    let range = selection.getRangeAt(rangeNumber);
    // Check if the selection is within a single text node
    if (
      range.startContainer === range.endContainer &&
      range.endContainer.nodeType === Node.TEXT_NODE
    ) {
      // Split the text node at the end offset
      range.startContainer.splitText(range.endOffset);
      // Get the text node at the start offset
      let textNode = range.startContainer.splitText(range.startOffset);
      rangeNodes.push(textNode);
    } else {
      // Handle cases where the selection spans multiple nodes
      /* edge-case for rare circumstances where, the end-container may contain a text node, but not be the text node itself, end-container is redefined for iterator */
      let startContainer = range.startContainer;
      let endContainer = range.endContainer;
      if (
        range.endContainer.nodeType != Node.TEXT_NODE &&
        range.endContainer.childNodes.length > range.endOffset
      )
        endContainer = range.endContainer.childNodes[range.endOffset];
      if (
        range.startContainer.nodeType != Node.TEXT_NODE &&
        range.startOffset > 0
      )
        startContainer = range.startContainer.childNodes[range.startOffset - 1];
      /* collect all text nodes inside range, ignore them if they are marked as non-selectable through css */
      let textIterator = document.createNodeIterator(
        range.commonAncestorContainer,
        NodeFilter.SHOW_TEXT,
        (node) =>
          node.compareDocumentPosition(startContainer) ==
            Node.DOCUMENT_POSITION_PRECEDING &&
          node.compareDocumentPosition(endContainer) ==
            Node.DOCUMENT_POSITION_FOLLOWING
            ? NodeFilter.FILTER_ACCEPT
            : NodeFilter.FILTER_REJECT
      );
      let node;
      while ((node = textIterator.nextNode())) {
        // Check if the text node is not whitespace and is selectable
        if (
          !isTextNodeUnparsedWhitespace(node) &&
          window.getComputedStyle(node.parentElement).userSelect != "none"
        )
          rangeNodes.push(node);
      }
      /* separate first and final text node */
      if (
        range.endContainer.nodeType === Node.TEXT_NODE &&
        window.getComputedStyle(range.endContainer.parentElement).userSelect !=
          "none"
      ) {
        range.endContainer.splitText(range.endOffset);
        rangeNodes.push(range.endContainer);
      }
      if (
        range.startContainer.nodeType === Node.TEXT_NODE &&
        window.getComputedStyle(range.startContainer.parentElement)
          .userSelect != "none"
      ) {
        rangeNodes.unshift(range.startContainer.splitText(range.startOffset));
      }
    }
    // Add the array of text nodes to the return array
    returnArray.unshift(rangeNodes);
  }
  return returnArray;
};

// Function to create and display the tooltip
const createTooltip = (
  parentElement,
  spanPosition,
  currentTooltipRef,
  currentSelectedTextRef,
  setAddFlashReportForm,
  setNegativeFlashReportForm,
  setPositiveFlashReportForm,
  setNeutralFlashReportForm,
  setActiveCommentIndex
) => {
  if (currentTooltipRef.current) {
    currentTooltipRef.current.remove();
    const tooltipElements = document.getElementsByClassName("outer-tooltip");

    // Iterate through the collection and remove each element
    for (let i = 0; i < tooltipElements.length; i++) {
      const element = tooltipElements[i];
      element.remove();
    }
  }

  const tooltip = document.createElement("div");
  tooltip.className = "flash-report-tooltip";

  const image = document.createElement("img");
  image.src = images.CommentPlus;
  const tooltipText = document.createElement("span");
  tooltipText.textContent = "Add Flash Report Title";

  tooltip.appendChild(image);
  tooltip.appendChild(tooltipText);

  // Set the position of the tooltip
  tooltip.style.top = spanPosition.top + "px";
  tooltip.style.left = spanPosition.left + "px";

  // Append the tooltip to the document body (or another appropriate parent element)
  parentElement.appendChild(tooltip);

  currentTooltipRef.current = tooltip;

  tooltip.addEventListener("click", function (event) {
    setAddFlashReportForm(true);
    tooltip.remove();
    currentTooltipRef.current = null;
    setNegativeFlashReportForm({
      negativeComment: false,
      negativeCommentId: null,
    });
    setPositiveFlashReportForm({
      positiveComment: false,
      positiveCommentId: null,
    });
    setNeutralFlashReportForm({
      neutralComment: false,
      neutralCommentId: null,
    });
    setActiveCommentIndex(null);
    event.stopPropagation();
  });
};
// Scroll to the target element
const handleActiveComment = (event, id, setActiveCommentIndex) => {
  // Prevent the default behavior of the link (scrolling to the anchor)
  event.preventDefault();
  setActiveCommentIndex(id);

  const aboutElement = document.getElementById(id);
  if (aboutElement) {
    // Scroll to the target element
    aboutElement.scrollIntoView({ behavior: "smooth" });
  }
};
// Function to add a new comment
const addNewComment = async (
  values,
  { resetForm, setFieldValue },
  currentSelectedTextRef,
  sentimentsData,
  setCharacterCount,
  setAddFlashReportForm,
  maintainPreviousComments,
  companyTicker,
  firstFileDetails,
  secondFileDetails,
  quarterValue,
  getFilesList,
  dataSpanId
) => {
  // Generate a dynamic ID for the new comment
  const dynamicId = Date.now();
  // Set the dynamic ID and class to the selected text
  currentSelectedTextRef.current.setAttribute("id", dynamicId);
  // currentSelectedTextRef.current.setAttribute("class", "custom-class");
  // Get the HTML document
  const newDocument = document.getElementById("flash-report-content-container");
  const htmlDoc = newDocument.innerHTML;
  // Create a new comment object with values and the dynamic ID
  const newComment = { values, id: dynamicId, dataSpanId };
  // Store the new comment in an array
  const comments = [newComment];
  // Reset form values and UI states
  setFieldValue("sentiments", sentimentsData[0]);
  resetForm();
  setCharacterCount(0);
  setAddFlashReportForm(false);
  // Check if there are previous comments
  if (maintainPreviousComments === null) {
    // If no previous comments, initialize flashSentiments
    const flashSentiments = {
      ticker: companyTicker,
      htmldoc: "html",
      positive: [],
      negative: [],
      neutral: [],
    };
    // Call the addFlashComment function with the necessary parameters
    await addFlashComment(
      comments,
      htmlDoc,
      flashSentiments,
      firstFileDetails,
      secondFileDetails,
      quarterValue,
      companyTicker,
      getFilesList
    );
  } else {
    // If there are previous comments, use existing flashSentiments
    await addFlashComment(
      comments,
      htmlDoc,
      maintainPreviousComments?.flash_sentiments,
      firstFileDetails,
      secondFileDetails,
      quarterValue,
      companyTicker,
      getFilesList
    );
  }
};
// Function to add flash comment
const addFlashComment = async (
  comments,
  htmlDoc,
  flashSentiments,
  firstFileDetails,
  secondFileDetails,
  quarterValue,
  companyTicker,
  getFilesList
) => {
  // Create the result object with file details, HTML document, and flashSentiments
  const result = {
    fileFirst_id: firstFileDetails?.id,
    fileSecond_id: secondFileDetails?.id,
    filing_type: quarterValue,
    ticker: companyTicker,
    file_type: "html",
    file_url: htmlDoc,
    flash_sentiments: flashSentiments,
  };
  // Iterate through comments and add them to the appropriate sentiment category
  comments?.forEach((item) => {
    const newItem = {
      id: item.id,
      title: item.values?.title,
      dataSpanId: item.dataSpanId,
      type: item.values?.sentiments?.text,
    };

    if (item.values?.sentiments?.text === "Positive") {
      result.flash_sentiments.positive.push(newItem);
    } else if (item.values?.sentiments?.text === "Negative") {
      result.flash_sentiments.negative.push(newItem);
    } else if (item.values?.sentiments?.text === "Neutral") {
      result.flash_sentiments.neutral.push(newItem);
    }
  });
  // Call the FlashReportService to save the flash report
  await FlashReportService.flashReport(result);
  // Update the files list
  getFilesList();
};
// Function to handle delete comments

const deleteComment = async (
  id,
  index,
  commentType,
  spansId,
  getComments,
  firstFileDetails,
  secondFileDetails,
  quarterValue,
  companyTicker,
  getFilesList,
  setNegativeFlashReportForm,
  setPositiveFlashReportForm,
  setNeutralFlashReportForm
) => {
  let updatedGetComments;
  const spanToRemove = document.querySelectorAll(`[data-span-id="${spansId}"]`);
  // Loop through each span and remove it
  spanToRemove.forEach((span) => {
    // Get the parent of the span
    let parentElement = span.parentNode;
    // Move the span's children (content) to the parent before removing the span
    while (span.firstChild) {
      parentElement.insertBefore(span.firstChild, span);
    }
    // Remove the empty span
    parentElement.removeChild(span);
  });

  // Get the updated HTML document after the comment deletion
  const newDocument = document.getElementById("flash-report-content-container");
  const htmlDoc = newDocument.innerHTML;

  // Example usage
  if (
    commentType === "negative" ||
    commentType === "positive" ||
    commentType === "neutral"
  ) {
    // Call the function to update the comment list after deletion
    updatedGetComments = updateCommentListAfterDeletion(
      commentType,
      index,
      getComments
    );
  }
  // Create the result object with file details, HTML document, and updated flashSentiments
  const result = {
    fileFirst_id: firstFileDetails?.id,
    fileSecond_id: secondFileDetails?.id,
    filing_type: quarterValue,
    ticker: companyTicker,
    file_type: "html",
    file_url: htmlDoc,
    flash_sentiments: updatedGetComments.flash_sentiments,
  };
  // Call the FlashReportService to save the updated flash report
  await FlashReportService.flashReport(result);
  // Update the files list
  getFilesList();
  // If we click on delete button all open forms should be closed
  setNegativeFlashReportForm({
    negativeComment: false,
    negativeCommentId: null,
  });
  setPositiveFlashReportForm({
    positiveComment: false,
    positiveCommentId: null,
  });
  setNeutralFlashReportForm({
    neutralComment: false,
    neutralCommentId: null,
  });
};

// Update Comment List after deletion
const updateCommentListAfterDeletion = (commentType, index, getComments) => {
  // Get the sentiment list based on the comment type
  const sentimentList = getComments?.flash_sentiments?.[commentType];
  // Check if the sentiment list exists
  if (sentimentList) {
    // Create an updated list by removing the comment at the specified index
    const updatedList = [...sentimentList];
    updatedList.splice(index, 1);
    // Return the updated comments object
    return {
      ...getComments,
      flash_sentiments: {
        ...getComments.flash_sentiments,
        [commentType]: updatedList,
      },
    };
  }

  // Handle the case where commentType is not found
  return getComments;
};

// Function to handle the editing of a comment
const handleEditComment = (
  id,
  commentType,
  getComments,
  setEditComment,
  setAddFlashReportForm,
  setNegativeFlashReportForm,
  setPositiveFlashReportForm,
  setNeutralFlashReportForm
) => {
  // Define a mapping between comment types and their corresponding icon and form type
  const sentimentMapping = {
    negative: { type: "negative", icon: "negative" },
    positive: { type: "positive", icon: "positive" },
    neutral: { type: "neutral", icon: "neutral" },
  };
  // Extract type and icon based on the commentType
  const { type, icon } = sentimentMapping[commentType];
  // Find the comment in the user data based on the comment type and ID
  let editInUserData = getComments?.flash_sentiments?.[type]?.find((value) => {
    return value.id === id;
  });
  // Check if the comment is found
  if (editInUserData) {
    // Update the icon in the user data
    editInUserData.icon = icon;
    // Set the edited comment for further processing
    setEditComment(editInUserData);
    // Close the add flash report form
    setAddFlashReportForm(false);

    // Set the corresponding form state based on the commentType
    setNegativeFlashReportForm({
      negativeComment: type === "negative",
      negativeCommentId: type === "negative" ? id : null,
    });

    setPositiveFlashReportForm({
      positiveComment: type === "positive",
      positiveCommentId: type === "positive" ? id : null,
    });

    setNeutralFlashReportForm({
      neutralComment: type === "neutral",
      neutralCommentId: type === "neutral" ? id : null,
    });
  }
};

const submitEditComment = async (
  values,
  { resetForm, setFieldValue },
  editComment,
  sentimentsData,
  setCharacterCount,
  getComments,
  getFilesList,
  setEditComment,
  firstFileDetails,
  secondFileDetails,
  quarterValue,
  companyTicker,
  setNegativeFlashReportForm,
  setPositiveFlashReportForm,
  setNeutralFlashReportForm
) => {
  // Map the form states to corresponding functions
  const setFormStates = {
    negativeFlashReportForm: setNegativeFlashReportForm,
    positiveFlashReportForm: setPositiveFlashReportForm,
    neutralFlashReportForm: setNeutralFlashReportForm,
  };
  // Determine the type of the comment (negative, positive, neutral)
  const commentType = editComment.icon.toLowerCase();
  // Check if the edited comment type matches the selected sentiment
  if (values.sentiments.text.toLowerCase() === commentType) {
    // Update the comment in the Flash Report
    await updateComment(
      values,
      commentType,
      getComments,
      editComment,
      firstFileDetails,
      secondFileDetails,
      quarterValue,
      companyTicker,
      getFilesList
    );
    // Reset form fields
    setFieldValue("sentiments", sentimentsData[0]);
    resetForm();
    setCharacterCount(0);
  } else {
    // If the sentiment is changed, update the comment type
    const updatedList = [...getComments?.flash_sentiments?.[commentType]];
    const findingIndex = getComments?.flash_sentiments?.[
      commentType
    ]?.findIndex((value) => value.id === editComment.id);

    if (findingIndex !== -1) {
      // Remove the edited comment from the current type
      updatedList.splice(findingIndex, 1);
      // Create an updated comment list with the new sentiment
      const updatedGetComments = {
        ...getComments,
        flash_sentiments: {
          ...getComments.flash_sentiments,
          [commentType]: updatedList,
          [values?.sentiments?.text.toLowerCase()]: [
            ...(getComments?.flash_sentiments?.[
              values?.sentiments?.text.toLowerCase()
            ] || []),
            {
              id: editComment.id,
              title: values?.title,
              dataSpanId: editComment.dataSpanId,
              type: values?.sentiments?.text,
            },
          ],
        },
      };
      // Prepare data for Flash Report update
      const result = {
        fileFirst_id: firstFileDetails?.id,
        fileSecond_id: secondFileDetails?.id,
        filing_type: quarterValue,
        ticker: companyTicker,
        file_type: "html",
        file_url: document.getElementById("flash-report-content-container")
          .innerHTML,
        flash_sentiments: updatedGetComments?.flash_sentiments,
      };
      // Update the Flash Report
      await FlashReportService.flashReport(result);
      getFilesList();
    }
    // Reset form fields
    setFieldValue("sentiments", sentimentsData[0]);
    resetForm();
    setCharacterCount(0);
  }
  // Reset the corresponding form state based on the commentType
  setFormStates[`${commentType}FlashReportForm`]({
    [`${commentType}Comment`]: false,
    [`${commentType}CommentId`]: null,
  });
  // Clear the edit comment state
  setEditComment("");
};

const updateComment = async (
  values,
  commentType,
  getComments,
  editComment,
  firstFileDetails,
  secondFileDetails,
  quarterValue,
  companyTicker,
  getFilesList
) => {
  // Retrieve the ID of the edited comment
  const getCommentId = editComment.id;
  // Find the index of the edited comment in the current comment type
  const findingIndex = getComments?.flash_sentiments?.[commentType]?.findIndex(
    (value) => value.id === getCommentId
  );
  // Check if the comment is found in the current type
  if (findingIndex !== -1) {
    // Replace the comment at the specified index with the updated content
    getComments?.flash_sentiments?.[commentType]?.splice(findingIndex, 1, {
      id: getCommentId,
      title: values.title,
      dataSpanId: editComment.dataSpanId,
      type: values?.sentiments?.text,
    });
    // Prepare data for Flash Report update
    const result = {
      fileFirst_id: firstFileDetails?.id,
      fileSecond_id: secondFileDetails?.id,
      filing_type: quarterValue,
      ticker: companyTicker,
      file_type: "html",
      file_url: document.getElementById("flash-report-content-container")
        .innerHTML,
      flash_sentiments: getComments?.flash_sentiments,
    };
    // Update the Flash Report
    await FlashReportService.flashReport(result);
    getFilesList();
  }
};

// Helper function to check if an array has items
const hasItems = (array) => Array.isArray(array) && array.length > 0;

export {
  validationSchema,
  hightLightText,
  handleActiveComment,
  addNewComment,
  deleteComment,
  handleEditComment,
  submitEditComment,
  hasItems,
};
